<script setup>
import {computed, ref} from "vue";
import {useUserStore} from "@/store";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";
import { getSberStatusTitleById } from "@/lib/auxiliary/sberbankStatuses.js"

const toast = useToast();
const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);

const logs = ref({});
const orderNumber = ref('');
const wcId = ref(1);
const buttonActive = ref(false);

const getLogs = async () => {
  buttonActive.value = !buttonActive.value;
  var searchSet = {};
  if (orderNumber.value !== '') {
    searchSet['order_number'] = orderNumber.value;
  }
  if (wcId.value !== '') {
    searchSet['wc_id'] = wcId.value;
  }
   await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/service/sberbank-log/get', {
    params: searchSet
  }).then(function (response) {
    let data = response.data.data;
    logs.value = data;
     if (logs.value.logs && logs.value.logs.length === 0) {
      toast.info("Логи не найдены")
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
  buttonActive.value = !buttonActive.value;
}
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Логи сбербанка</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Сервисы</a></li>
              <li class="breadcrumb-item"><router-link :to="{ name: 'SberbankLogPage' }" class="">Сбербанк логи</router-link></li>
              <li class="breadcrumb-item active">Лог</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-search mr-2"></i>Поиск
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-2">
                    <label for="wcId" class="col-form-label">Город</label>
                    <select id="wcId" class="form-control" autocomplete="off" v-model="wcId">
                      <option :value=1>Санкт-Петербург</option>
                      <option :value=2>Москва</option>
                    </select>
                  </div>
                  <div class="form-group col-sm-2">
                    <label class="col-form-label">Номер заказа</label>
                    <input type="text" class="form-control" placeholder="xxxxx-xxxx-xxx" v-model="orderNumber">
                  </div>
                  <div class="form-group col-sm-auto align-bottom">
                    <label class="col-form-label col-12">&nbsp;</label>
                    <button type="submit" class="btn btn-primary waves-effect waves-light" @click="getLogs">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="buttonActive"></span>
                      Найти
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card" v-if="logs.logs && logs.logs.length > 0">
              <div class="card-body table-responsive">
                <table class="table table-head-fixed text-nowrap table-bordered table-sm">
                  <thead>
                  <tr>
                    <th>ID Лога</th>
                    <th>ID Заказа</th>
                    <th>ID Транзакции</th>
                    <th>Статус</th>
                    <th>ИП</th>
                    <th>Ответ</th>
                    <th>Сумма</th>
                    <th>Автосписание</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in logs.logs" :key="index">
                    <td>{{ item.id }}</td>
                    <td>{{ item.order_id }}</td>
                    <td>{{ item.transaction_id}}</td>
                    <td>{{ getSberStatusTitleById(item.status_id) }}</td>
                    <td>{{ item.cashier }} </td>
                    <td>{{ item.message }}</td>
                    <td>{{ item.amount !== 0 ? item.amount : 'Нет информации' }}</td>
                    <td>{{ item.is_auto ? 'Да' : 'Нет' }} </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
</style>