<script setup>
import { useUserStore } from "@/store";
import { computed, onBeforeMount, ref } from "vue";
import api from "@/common/api";
import CustmersTable from "./CutomersTable.vue"
import CustomerFilters from "./CustomerFilters.vue"
import { searchClientsApiURLs } from '@/lib/auxiliary/customerSearch'

const store = useUserStore();
const isLoadingSearchBtn = ref(false)
const isAuthenticated = computed(() => store.user.isAuthenticated);

const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function () {
  });
}

const fetchClientsAsync = async (searchBy = 'phone') => {
  try {
    isLoadingSearchBtn.value = true
    const { data } = await api.get(process.env.VUE_APP_GATE_URL + searchClientsApiURLs[searchBy], { params: clientSearchForm.value })
    clients.value = data.data;
  } catch (error) {
    console.error(error)
  } finally {
    isLoadingSearchBtn.value = false
  }
}

const clientSearchForm = ref({
  phone: '',
  last_name: '',
  first_name: '',
  patronymic: '',
  company: '',
  street: '',
  water_company_id: 1
})
const filterErrors = ref([])

const clients = ref([])

onBeforeMount(() => {
  checkAuth();
});
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <div class="content-header">
      <div class="container-fluid">
        <h1>Клиенты</h1>
      </div>
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <CustomerFilters 
                v-model="clientSearchForm" 
                :isLoadingSearchBtn="isLoadingSearchBtn"
                :errors="filterErrors" 
                @search="fetchClientsAsync" 
                @new-error="filterErrors = $event" 
              />
              <CustmersTable :clients="clients" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
