<script setup>


import {useUserStore} from "@/store";
import {computed, onBeforeMount} from "vue";
import api from "@/common/api";

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function () {
  });
}

onBeforeMount(() => {
  checkAuth();
});

</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <div class="content-header">
      <div class="container-fluid">
        <h1>Личный кабинет</h1>
      </div>
    </div>
  </div>
</template>




