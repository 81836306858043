<script setup>
import {showErrors} from "@/common/showErrors";
import router from "@/router";
import {useUserStore} from "@/store";
import axios from "axios";
import {ref} from "vue";
import {useToast} from "vue-toastification";
import {vMaska} from "maska"

const toast = useToast();

const user = ref({
  code: "",
  phone: {}
});
const smsSend = ref(false);

const userStore = useUserStore();

const sendSms = () => {
  // smsSend.value = true; // Test
  axios.post(process.env.VUE_APP_GATE_URL + '/api/staff/send-sms', {
    phone: '7' + user.value.phone.unmasked
  }).then(({data}) => {
    console.log(data.data)
    if (data.data) {
      smsSend.value = true;
      toast.success("Код отправлен");
    }
  }).catch(({response}) => {
    smsSend.value = false;
    showErrors(response);
  })
}

const login = () => {
  axios.post(process.env.VUE_APP_GATE_URL + '/api/staff/sign-in', {
    phone: '7' + user.value.phone.unmasked,
    sms_code: user.value.code
  }).then(({data}) => {
    if (data.data) {
      smsSend.value = false;
    }
    console.log(data.data);
    localStorage.access_token = data.data.access_token;
    userStore.setUserRole(data.data.role)
    userStore.setAuthentication(true);
    router.push({name: 'StaffProfile'});
  }).catch(({response}) => {
    showErrors(response);
  })
}

</script>

<template>
  <head>
    <title>Войти</title>
  </head>
  <div class="container-fluid">
    <div id="cardSignIn" class="row justify-content-md-center">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="card">
          <div class="card-header">
            Личный кабинет
          </div>
          <div class="card-body">
            <div id="formSignIn">
              <div class="input-group mb-2">
                <span class="input-group-text mdi mdi-phone fa fa-phone"></span>
                <input type="text" class="form-control validate-fill" name="phone" placeholder="Телефон"
                       v-maska="user.phone" data-maska="+7 (###) ###-##-##">
              </div>
              <div class="input-group mb-2" v-if="smsSend">
                <span class="input-group-text mdi mdi-lock fa fa-lock"></span>
                <input type="text" class="form-control validate-fill" placeholder="Код" v-model="user.code" max="4"
                       min="0">
              </div>
              <div class="mb-2 text-end">
                <button class="btn btn-primary" @click="sendSms" v-if="!smsSend">
                  Получить код по SMS
                </button>
                <button class="btn btn-primary" @click="login" v-else>
                  Войти
                </button>
              </div>
              <small class="form-text text-muted">
                <router-link :to="{name: 'SingIn'}">
                  Войти в Админпанель
                </router-link>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import '../../css/style.css';
</style>