<script setup>

import ru from "date-fns/locale/ru";
import Datapicker from "vue3-datepicker";
import {computed, onBeforeMount, ref} from "vue";
import {useUserStore} from "@/store";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import StaffTotalData from "@/components/Staff/StaffTotalData.vue";
import StaffRouteSheets from "@/components/Staff/StaffRouteSheets.vue";
import {useToast} from "vue-toastification";
import format from "date-fns/format";
import {VueSpinnerClock} from "vue3-spinners";

const toast = useToast();
const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);

const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function (error) {
    showErrors(error.response)
  });
}

const dateFrom = ref(new Date());
const dateTo = ref(new Date());
const routeSheets = ref([]);
const totalData = ref(false);
const profile = ref([]);
const loading = ref(false);


const getRouteSheets = async () => {
  loading.value = true
  api.get(process.env.VUE_APP_GATE_URL + '/salary/api/v1/staff/route-sheets-list', {
    params: {
      dateFrom: format(dateFrom.value, 'yyyy-MM-dd'),
      dateTo: format(dateTo.value, 'yyyy-MM-dd'),
    }
  }).then(function (response) {
    routeSheets.value = response.data.data.dates ? response.data.data.dates : []
    totalData.value = response.data.data.summary
    if (routeSheets.value.length === 0) {
      toast.info("Маршрутные листы не найдены")
    }
    loading.value = false
  }).catch(function (error) {
    loading.value = false
    showErrors(error.response)
    return []
  })
}

// const getTotalData = async () => {
//   api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/staff/summary', {
//     params: {
//       dateFrom: format(dateFrom.value, 'yyyy-MM-dd'),
//       dateTo: format(dateTo.value, 'yyyy-MM-dd'),
//     }
//   }).then(function (response) {
//     totalData.value = response.data.data
//     if (totalData.value.length === 0) {
//       toast.info("Общие данные не найдены")
//     }
//   }).catch(function (error) {
//     showErrors(error.response)
//     return []
//   })
// }
//
const getProfile = async () => {
  api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/staff/profile').then(function (response) {
    profile.value = response.data.data
    if (profile.value.length === 0) {
      toast.info("Профиль сотрудника не найден")
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
}

onBeforeMount(() => {
  checkAuth();
  dateFrom.value = new Date(dateFrom.value.setDate(dateFrom.value.getDate() - 1));
  dateTo.value = new Date(dateFrom.value.setDate(dateTo.value.getDate() - 1));
  getProfile();
});

</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Личный кабинет {{ profile.fullName }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
              <li class="breadcrumb-item active">Личный кабинет</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="vuespinner-container" v-if="loading">
        <div class="custom-spinner">
          <VueSpinnerClock size="125" color="darkslategray"/>
          <p class="spinner-text">Получаем данные...</p>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-3">
            <div class="card">
              <div class="card-header ui-sortable-handle" style="cursor: move;">
                <h3 class="card-title">
                  <i class="fas fa-filter mr-1"></i>Фильтр
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="wcId" class="col-form-label">Дата c</label>
                    <Datapicker
                        class="form-control"
                        v-model="dateFrom"
                        :locale="ru"
                        input-format='dd.MM.yyyy'
                        typeable
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="wcId" class="col-form-label">Дата по</label>
                    <Datapicker
                        class="form-control"
                        v-model="dateTo"
                        :locale="ru"
                        :lower-limit="dateFrom"
                        input-format='dd.MM.yyyy'
                        typeable
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <button class="btn btn-primary waves-effect waves-light" @click="(getRouteSheets())">
                      Найти
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <StaffTotalData :totalData="totalData" v-if="totalData"/>
            <StaffRouteSheets
                v-for="(routeSheet, date) in routeSheets"
                :item="routeSheet"
                :key="date"
                :date="date"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>
</template>



<style scoped> .custom-spinner {
  z-index: 1000; /* Установите нужное значение z-index */
  position: fixed; /* Фиксированное позиционирование */
  top: 50%; /* Центрирование по вертикали */
  left: 50%; /* Центрирование по горизонтали */
  transform: translate(-50%, -50%); /* Центрирование по оси */
  background: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
  width: 100vw; /* Полная ширина экрана */
  height: 100vh; /* Полная высота экрана */
  display: flex;
  flex-direction: column; /* Вертикальное расположение элементов */
  justify-content: center;
  align-items: center;
}

.spinner-text {
  margin-top: 20px; /* Отступ сверху */
  font-size: 1.5em; /* Размер шрифта */
  color: darkslategray; /* Цвет текста */
} </style>
