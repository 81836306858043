<script setup>
import { onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";
import {useRoute} from "vue-router";
import DepartmentPageMenu from "@/components/Departments/DepartmentMenu.vue";

const department = ref({
  name: '',
  waterCompaniesIds: [],
  ownerId: null,
  owner: null
})

const users = ref([]);
const departmentUsers = ref([]);
const targetUserIdForAdd = ref(null);
const targetUserIdForDelete = ref(null);


const getUsersWithoutOwner = async () => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/users-without-owner').then(function (response) {
    users.value = response.data.data
  }).catch(function (error) {
    showErrors(error)
  })
}

const getDepartmentUsers = async (id) => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + id + '/users').then(function (response) {
    departmentUsers.value = response.data.data.filter(user => user.id !== department.value.ownerId);
  }).catch(function (error) {
    showErrors(error)
  })
}

const addUserToDepartment = async (userId) => {
  await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + route.params.id + '/user/' + userId).then(function () {
    toast.success("Сотрудник добавлен")
    getUsersWithoutOwner();
    getDepartmentUsers(route.params.id);
  }).catch(function (error) {
        showErrors(error)
      }
  )
}

const deleteUserFromDepartment = async (userId) => {
  await api.delete(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + route.params.id + '/user/' + userId).then(function () {
    toast.success("Сотрудник удалён из отдела")
    getUsersWithoutOwner();
    getDepartmentUsers(route.params.id);
  }).catch(function (error) {
        showErrors(error)
      }
  )
}

const toast = useToast();

const getDepartment = (id) => {
  api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + id).then(function (response) {
    department.value.name = response.data.data.name
    department.value.waterCompaniesIds = response.data.data.water_companies.map(company => company.water_company_id);
    department.value.ownerId = response.data.data.owner_id
    department.value.owner = response.data.data.owner

  }).catch(function (error) {
    showErrors(error.response)
  })
}


const route = useRoute();
onBeforeMount(() => {
  getDepartment(route.params.id);
  getUsersWithoutOwner();
  getDepartmentUsers(route.params.id);

});
</script>
<template>
  <div class="content-wrapper"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Пользователи отдела {{department.name}}</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Отделы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <DepartmentPageMenu></DepartmentPageMenu>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Список сотрудников без отдела</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" size="25" v-model="targetUserIdForAdd">
                        <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info" @click="addUserToDepartment(targetUserIdForAdd)">Добавить в
                    отдел
                  </button>
                </div>
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info" @click="deleteUserFromDepartment(targetUserIdForDelete)">
                    Удалить из отдела
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Сотрудники в отделе</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" v-model="targetUserIdForDelete" size="25">
                        <option v-for="user in departmentUsers" :key="user.id" :value="user.id">
                          {{ user.name }} | {{user?.perm_service_post?.name}}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>
