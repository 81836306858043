<script setup>
import {onBeforeMount, ref} from "vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import router from "@/router";
import UIInput from "@/components/UI/UIInput.vue";
import {useRoute} from "vue-router";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";
import format from "date-fns/format";
import {inputMethods, moneyTypes, operationTypes} from "@/components/Transaction/types";

const route = useRoute();
const toast = useToast();

class transactionModel {
  staffId = route.params.staffId
  operationType = null
  amount = null
  moneyType = null
  inputMethod = null
  reason = null
  transactionDate = null
  localTransactionDate = null
}

const transaction = ref(new transactionModel());


const createTransaction = async () => {
  try {
    transaction.value.transactionDate =  transaction.value.localTransactionDate ? format(new Date(transaction.value.localTransactionDate), 'yyyy-MM-dd HH:mm:ss') : null ;
    await api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/balance-transactions', transaction.value)
    toast.success('Успешно')
  } catch (error) {
    showErrors(error)
  }
}

onBeforeMount(() => {
});
</script>
<template>
  <div class="content-wrapper"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Добавить новую транзакцию</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Транзакции</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <div>
                        <label>Тип операции</label>
                        <select class="form-control" autocomplete="off" v-model="transaction.operationType">
                          <option v-for="operationType in operationTypes" :value="operationType.value"
                                  :key="operationType.value">{{ operationType.name }}
                          </option>
                        </select>
                      </div>
                      <div>
                        <label>Тип денег</label>
                        <select class="form-control" autocomplete="off" v-model="transaction.moneyType">
                          <option v-for="moneyType in moneyTypes" :value="moneyType.value"
                                  :key="moneyType.value">{{ moneyType.name }}
                          </option>
                        </select>
                      </div>
                      <UIInput label="Причина" v-model="transaction.reason" class="col-md-12"></UIInput>
                      <div>
                        <label>Способ внесения</label>
                        <select class="form-control" autocomplete="off" v-model="transaction.inputMethod">
                          <option v-for="inputMethod in inputMethods" :value="inputMethod.value"
                                  :key="inputMethod.value">{{ inputMethod.name }}
                          </option>
                        </select>
                      </div>
                      <UIInput label="Сумма" v-model="transaction.amount" class="col-md-12" type="number"></UIInput>
                      <div>
                        <label>Дата</label>
                        <VueDatePicker v-model="transaction.localTransactionDate" class="col-md-12" locale="ru"
                                       format="yyyy-MM-dd HH:mm:ss"></VueDatePicker>
                      </div>
                    </div>
                  </form>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-info" @click="createTransaction()">Добавить</button>
                    <button type="submit" class="btn btn-default float-right"
                            @click="router.go(-1)">Назад
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>
