<script setup>


import {useUserStore} from "@/store";
import {computed, onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import ru from "date-fns/locale/ru";
import Datapicker from "vue3-datepicker";
import format from "date-fns/format";
import { parse } from 'date-fns';
import {useToast} from 'vue-toastification';
import SalaryMenu from "@/components/Salary/SalaryMenu.vue";
const toast = useToast();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);


const weekends = ref([]);
const date = ref(new Date());
const formattedDate = computed(() => format(date.value, 'yyyy-MM-dd'));
const formatDate = (dateString) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  return format(date, 'dd.MM.yyyy');
};
const getWeekends = async () => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/weekends/').then(function (response) {
    weekends.value = response.data
  }).catch(function (error) {
    showErrors(error)
    return []
  })
}

const addWeekend = async () => {
  await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/weekend/', {
    date: formattedDate.value
  }).then(function () {
    getWeekends()
    toast.success("День добавлен")
  }).catch(function (response) {
    console.log(response)
    showErrors(response)
  })
}

const deleteWeekend = async (date) => {
  await api.delete(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/weekend?date=' + date).then(function () {
    getWeekends()
    toast.success("День удалён")
  }).catch(function (response) {
    console.log(response)
    showErrors(response)
  })
}

onBeforeMount(() => {
  getWeekends();
});

</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1 class="m-0">График выходных дней</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'DriverSalaryPage'}">Заработная плата водителей</router-link>
              </li>
              <li class="breadcrumb-item active">График выходных дней</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <SalaryMenu></SalaryMenu>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Добавить</h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <Datapicker
                          class="form-control"
                          v-model="date"
                          :locale="ru"
                          input-format='dd.MM.yyyy'
                          typeable
                      />
                    </div>
                    <div class="form-group col-md-12" style="text-align: center;">
                      <button class="btn btn-success btn-sm" @click="addWeekend()">Добавить</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="form-row">
                  <table class="table table-striped table-sm table-bordered">
                    <thead>
                    <tr>
                      <th style="text-align: center;">Дата</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(weekend, index) in weekends.data" :key="index">
                      <td style="text-align: center;">{{ formatDate(weekend.date) }}</td>
                      <td style="text-align: center;">
                        <button class="btn btn-danger btn-sm" @click="deleteWeekend(weekend.date)">Удалить</button>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>
</template>

<style scoped>

</style>