<template>
    <div class="form-group col-md-2 col-xl-2 ui-input">
        <label :for="UIInput?.id" class="col-form-label">{{ props.label }}</label>
        <input v-uid ref="UIInput" type="text" class="form-control" :value="props.modelValue"
            @input="emits('update:modelValue', $event.target.value)">
            <p class="ui-input__error">{{ getErrors[0] }}</p>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';

const props = defineProps({
    modelValue: { type: [String, Number], required: true },
    label: { type: String, required: false, default: () => '' },
    minLength: { type: Number, required: false, default: () => 0 },
    required: { type: Boolean, required: false, default: () => false },
    errors: { type: Array, required: false, default: () => [] },
    validate: { type: Boolean, required: false, default: () => false }
})

const emits = defineEmits(['update:modelValue', 'new-error'])

const UIInput = ref(null)

const getErrors = computed(() => {
    if (!props.validate) {
        return [];
    }

    const newErrors = []
    if (props.required && !props.modelValue) {
        newErrors.push('Поле обязательное')
    }
    if (props.minLength && props.modelValue && String(props.modelValue).length < props.minLength) {
        newErrors.push('Требуется больше символов')
    }

    emits('new-error', newErrors)

    return newErrors
})
</script>

<style scoped>
.ui-input {
    position: relative;
}
.ui-input__error {
    position: absolute;
    margin: 0px;
    bottom: -20px;
    font-size: 14px;
    color: red; 
}
</style>