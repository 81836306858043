<script setup>
import {defineProps, ref, watch} from 'vue';
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from 'vue-toastification';

const toast = useToast();
const props = defineProps({
  summary: {
    type: Object,
    required: true,
    totalBottles: {
      type: Object,
      required: true
    },
    totalMoney: {
      type: Object,
      required: true
    },
    bonuses: {
      type: Object,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  }
});

let bonuses = ref(props.summary.bonuses);

watch(() => props.summary, (newValue) => {
   bonuses = ref(newValue.bonuses)
});

const applyBonuses = (bonuses) => {
  api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/delivery-request/' + props.summary.id + '/update-bonuses', {
    bonuses: bonuses,
  }).then(({data}) => {
    if (data) {
      toast.success("Заказ обновлен");
    }
  }).catch(({response}) => {
    showErrors(response)
  });
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-auto">
          <table class="table table-bordered table-sm">
            <tbody>
            <tr>
              <td>Загружено</td>
              <td><b>{{ props.summary.totalBottles.uploaded }}</b>&nbsp;б</td>
            </tr>
            <tr>
              <td>Доставлено</td>
              <td><b>{{ props.summary.totalBottles.delivered }}</b>&nbsp;б</td>
            </tr>
            <tr>
              <td>Не доставлено</td>
              <td>{{ props.summary.totalBottles.notDelivered }}&nbsp;б</td>
            </tr>
            <!-- если надо посчитать на беке -->
            <!--            <tr>-->
            <!--              <td>Забрать тару</td>-->
            <!--              <td>{{ props.summary.totalBottles.brought }}&nbsp;б</td>-->
            <!--            </tr>-->
            <tr>
              <td>Бутылок вернули</td>
              <td>{{ props.summary.totalBottles.returned }}&nbsp;б</td>
            </tr>
            <!--            <tr>-->
            <!--              <td>Вернули</td>-->
            <!--              <td>{{ props.summary.totalBottles.took }}&nbsp;б</td>-->
            <!--            </tr>-->
            </tbody>
          </table>
        </div>
        <div class="col-auto">
          <table class="table table-bordered table-sm">
            <tbody>
            <tr>
              <td>Б/нал</td>
              <td><b>{{ props.summary.totalMoney.cashless }}</b>&nbsp;&#8381;</td>
            </tr>
            <tr>
              <td>Картой</td>
              <td><b>{{ props.summary.totalMoney.byCard }}</b>&nbsp;&#8381;</td>
            </tr>
            <tr>
              <td>Отменено</td>
              <td>{{ props.summary.totalMoney.canceled }}&nbsp;&#8381;</td>
            </tr>
            <tr>
              <td>Частично</td>
              <td>{{ props.summary.totalMoney.partially }}&nbsp;&#8381;</td>
            </tr>
            <tr>
              <td>Переплата</td>
              <td>{{ props.summary.totalMoney.overPayment }}&nbsp;&#8381;</td>
            </tr>
            <tr>
              <td>Отдать</td>
              <td>{{ props.summary.totalMoney.giveAway }}&nbsp;&#8381;</td>
            </tr>
            <tr>
              <td>Нал</td>
              <td>{{ props.summary.totalMoney.cash }}&nbsp;&#8381;</td>
            </tr>
            <tr>
              <td>Итого (нал)</td>
              <td><b>{{ props.summary.totalMoney.totalCash }}</b>&nbsp;&#8381;</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-auto">
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="bonuses.suburbanBonus"
                     @change="applyBonuses(bonuses)">
              <label class="form-check-label">Доплата за пригород</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="bonuses.centerBonus"
                     @change="applyBonuses(bonuses)">
              <label class="form-check-label">Доплата за центр</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="bonuses.gatchinaBonus"
                     @change="applyBonuses(bonuses)">
              <label class="form-check-label">Доплата за Гатчина (СПб)</label>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>