
export const sberStatuses = [
    {
        id: 0,
        title: "Создан" 
    },
    {
        id: 1,
        title: "Подтверждён" 
    },
    {
        id: 2,
        title: "Успех" 
    },
    {
        id: 3,
        title: "Полная отмена" 
    },
    {
        id: 4,
        title: "Успешный возврат" 
    },
    {
        id: 5,
        title: "Успех 3 DS" 
    },
    {
        id: 6,
        title: "Отклонён" 
    }
]

export const getSberStatusTitleById = (id = 0) => {
    const sourceStatus = sberStatuses.find((status) => status.id === +id)
    return sourceStatus.title
}
