<script setup>
import {computed, ref} from "vue";
import {useUserStore} from "@/store";
import api from "@/common/api";
import format from 'date-fns/format';
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import ru from "date-fns/locale/ru";
import Datapicker from "vue3-datepicker";
import AutoPaymentMenu from "@/components/AutoPayment/AutoPaymentMenu.vue";
const toast = useToast();
const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);

const wcId = ref('1');
const logs = ref({});
const phone = ref('');
const date = ref(null);
const nowDate = computed(() => format(new Date(), 'dd.MM.yyyy'));
const buttonActive = ref(false);
const getLogs = async (page) => {
  buttonActive.value = !buttonActive.value;
  if (typeof(page) !== 'number') {
    page = 1;
  }
  var searchSet = {};
  if (wcId.value !== '') {
    searchSet['wc_id'] = wcId.value;
  }
  if (phone.value !== '') {
    searchSet['phone'] = phone.value;
  }
  if (date.value !== null) {
    searchSet['date'] = format(date.value, 'yyyy-MM-dd');
  }
   await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/auto-payment/logs?page=' + page, {
    params: searchSet
  }).then(function (response) {
    let data = response.data.data;
    logs.value = data;
     if (logs.value.logs && logs.value.logs.length === 0) {
      toast.info("Логи не найдены")
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
  buttonActive.value = !buttonActive.value;
}
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Логи автосписания</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Сервисы</a></li>
              <li class="breadcrumb-item"><router-link :to="{ name: 'AutoPaymentPage' }" class="">Автосписание</router-link></li>
              <li class="breadcrumb-item active">Лог</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <AutoPaymentMenu></AutoPaymentMenu>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-search mr-2"></i>Поиск
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-2">
                    <label for="wcId" class="col-form-label">Город</label>
                    <select id="wcId" class="form-control" autocomplete="off" v-model="wcId">
                      <option :value=1>Санкт-Петербург</option>
                      <option :value=2>Москва</option>
                    </select>
                  </div>
                  <div class="form-group col-sm-2">
                    <label class="col-form-label">Номер телефона</label>
                    <input type="text" class="form-control" placeholder="79999999999" v-model="phone">
                  </div>
                  <div class="form-group col-sm-2">
                    <label class="col-form-label">Дата</label>
                    <Datapicker
                        class="form-control"
                        v-model="date"
                        :locale="ru"
                        input-format='dd.MM.yyyy'
                        :placeholder="nowDate"
                        typeable
                        clearable
                    />
                  </div>
                  <div class="form-group col-sm-auto align-bottom">
                    <label class="col-form-label col-12">&nbsp;</label>
                    <button type="submit" class="btn btn-primary waves-effect waves-light" @click="getLogs">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="buttonActive"></span>
                      Найти
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card" v-if="logs.logs && logs.logs.length > 0">
              <div class="card-body table-responsive">
                <table class="table table-head-fixed text-nowrap table-bordered table-sm">
                  <thead>
                  <tr>
                    <th>Город</th>
                    <th>ФИО</th>
                    <th>ID клиента</th>
                    <th>ID заказа</th>
                    <th>№ заказа</th>
                    <th>Сумма списания ₽</th>
                    <th>Статус списания</th>
                    <th>Ответ банка</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in logs.logs" :key="index">
                    <td>{{ item.water_company.name }}</td>
                    <td>{{ item.first_name }} {{ item.last_name }} {{ item.patronymic }}</td>
                    <td>{{ item.customer_id}}</td>
                    <td>{{ item.order_id }}</td>
                    <td>{{ item.order_number }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.status_id === 2 ? 'Успех' : 'Ожидание' }} </td>
                    <td>{{ item.data?.data ? item.data.data : 'Нет информации' }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Bootstrap5Pagination :data="logs" @pagination-change-page="getLogs($event)"/>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
</style>