<template>
    <div class="form-group col-md-2 col-xl-2">
        <label :for="UISelect?.id" class="col-form-label">{{ props.label }}</label>
        <select v-uid ref="UISelect" class="form-control" autocomplete="off" :value="modelValue"
            @input="emits('update:modelValue', $event.target.value)">
            <option v-for="option in mappedOptions" :value="option.value" :key="option.value">{{ option.title }}
            </option>
        </select>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue';

const props = defineProps({
    modelValue: { type: [String, Number], required: true },
    label: { type: String, required: false, default: () => '' },
    options: { type: Array, required: false, default: () => [] },
    mapOptions: { type: Function, required: false, default: (option) => option }
})

const emits = defineEmits(['update:modelValue'])

const mappedOptions = computed(() => {
    return props.options.map((option) => props.mapOptions(option))
})

const UISelect = ref(null)
</script>