<script setup>

import {useUserStore} from "@/store";
import {computed, ref} from "vue";
import api from "@/common/api";
import ru from "date-fns/locale/ru";
import Datapicker from "vue3-datepicker";
import format from "date-fns/format";
import {useToast} from 'vue-toastification';
import SalaryMenu from "@/components/Salary/SalaryMenu.vue";
import FileSaver from "file-saver";
const toast = useToast();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);


const date = ref(new Date());
const formattedDate = computed(() => format(date.value, 'yyyy-MM-dd')+' 23:59:59');


const getBalances = async () => {
  try {
    const {data} = await api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/balance-transactions/of-date/excel',
        {
          date: formattedDate.value,
        }, {responseType: 'blob'})
    //todo сделать обработку blob
    FileSaver.saveAs(data, 'Балансы.xlsx')
  } catch (error) {
    toast.error("Не удалось скачать файл")
  }
}
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1 class="m-0">Скачать балансы по всем водителям</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'DriverSalaryPage'}">Заработная плата водителей</router-link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <SalaryMenu></SalaryMenu>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Excel</h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <Datapicker
                          class="form-control"
                          v-model="date"
                          :locale="ru"
                          input-format='dd.MM.yyyy'
                          typeable
                      />
                    </div>
                    <div class="form-group col-md-12" style="text-align: center;">
                      <button class="btn btn-success btn-sm" @click="getBalances()">Скачать</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>
</template>

<style scoped>

</style>