<script setup>
import {computed, ref} from "vue";
import {useUserStore} from "@/store";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";
import StaffItem from "@/components/Staff/StaffItem.vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';
import debounce from 'lodash/debounce';

const toast = useToast();
const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const waterCompanyId = ref(1);
const statusId = ref(null);
const typeId = ref(null);
const hasIp = ref(null);
const filter = ref(null);
const staffList = ref([
  {
    staff: []
  }
]);

const filterObj = computed(() => {
  const obj = {
    waterCompanyId: waterCompanyId.value,
    statusId: statusId.value,
    typeId: typeId.value,
    hasIP: hasIp.value,
    filter: filter.value
  };
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== null));
});

// const getStaff = async (page) => {
//  await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/staff/?page=' + page, filterObj.value).then(function (response) {
//     staffList.value = response.data.data
//     if (staffList.value.staff.length === 0) {
//       toast.info("Персонал не найден")
//     }
//   }).catch(function (error) {
//     showErrors(error.response)
//     return []
//   })
// }

const getStaff = debounce(async (page) => {
  await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/staff/?page=' + page, filterObj.value).then(function (response) {
    staffList.value = response.data.data
    if (staffList.value.staff.length === 0) {
      toast.info("Персонал не найден")
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
}, 1000);

</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Водители и экспедиторы</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}">Сотрудники</router-link>
              </li>
              <li class="breadcrumb-item active">Водители и экспедиторы</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-3">
            <div class="card">
              <div class="card-header ui-sortable-handle" style="cursor: move;">
                <h3 class="card-title">
                  <i class="fas fa-filter mr-1"></i>Фильтр
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="wcid" class="col-form-label">Компания</label>
                    <select id="wcid" name="wcid" class="form-control" autocomplete="off" v-model="waterCompanyId">
                      <option :value=1 selected="">Хваловские Воды (С-Пб)</option>
                      <option :value=2>Хваловские Воды (Мск)</option>
                    </select>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="status_id" class="col-form-label">Статус</label>
                    <select name="status_id" id="status_id" class="form-control" v-model="statusId">
                      <option :value=null selected="">Все</option>
                      <option :value=1>Работает</option>
                      <option :value=2>Не работает</option>
                      <option :value=3>Заблокирован</option>
                    </select>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="type-id" class="col-form-label">Тип</label>
                    <select name="type_id" id="type-id" class="form-control" v-model="typeId">
                      <option :value=null selected="">Все</option>
                      <option :value=1>Водитель</option>
                      <option :value=2>Экспедитор</option>
                    </select>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="is_ip" class="col-form-label">Есть ИП</label>
                    <select name="is_ip" id="is_ip" class="form-control" v-model="hasIp">
                      <option :value=null selected="">Все</option>
                      <option :value=true>Да</option>
                      <option :value=false>Нет</option>
                    </select>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-12">
                    <button type="submit" class="btn btn-primary waves-effect waves-light" @click="getStaff(1)">
                      Найти
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-lg-9 col-md-9">
            <div class="card">
              <div class="card-body">
                <div class="d-flex col-12">
                  <div class="col-md-4 col-12">
                    <div class="input-group">
                      <input id="search" name="search" type="text" class="form-control" placeholder="Поиск"
                             aria-describedby="basic-addon2" v-model="filter" @keyup="getStaff(1)">
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"><i class="nav-icon fas fa-search"></i></span>
                      </div>
                    </div>
                  </div>
                  <small id="searchHelp" class="form-text text-muted">Внимание! Не учитывается в выгрузке 1С</small>

<!--                  <div class="ml-4 ml-auto">-->
<!--                    <a href="#" class="btn btn-primary waves-effect waves-light"-->
<!--                       style="margin-bottom: 10px;"><i class="fas fa-plus"></i> Добавить</a>-->
<!--                    <a class="btn btn-light waves-effect waves-light" id="btn_1c" title="Скачать выгрузку для 1С"-->
<!--                       href="#" style="margin-bottom: 10px;"><img-->
<!--                        src="@/assets/img1c.png"></a>-->
<!--                  </div>-->
                </div>
                <StaffItem
                    v-if="staffList.staff && staffList.staff.length > 0"
                    :data="staffList.staff"

                ></StaffItem>
                <Bootstrap5Pagination :data="staffList" @pagination-change-page="getStaff"
                                      :limit=10>
                </Bootstrap5Pagination>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>

</template>

<style scoped>

</style>