<script setup>
import {defineProps} from "vue";

const props = defineProps({
  totalData: {
    type: Object,
    required: true
  }
});

</script>

<template>
  <div class="card">
    <div class="card-body">
      <h1>Всего за выбранный период</h1>
      <div class="row">
        <div class="col-6">
          <table class="table table-bordered table-sm">
            <tbody>
            <tr>
              <td>Всего заявок</td>
              <td>{{ props.totalData.totalDeliveryRequest }}</td>
            </tr>
            <tr>
              <td>Всего бутылей</td>
              <td>{{ props.totalData.totalBottles }}</td>
            </tr>
            <tr>
              <td>Всего доставленных точек</td>
              <td>{{ props.totalData.totalOrders }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <table class="table table-bordered table-sm">
            <tbody>
            <tr>
              <td>Всего рублей к сдаче в кассу</td>
              <td>{{ props.totalData.amountCashReturn }}&nbsp;&#8381;</td>
            </tr>
            <tr>
              <td>Всего сданных средств в офисе</td>
              <td>{{ props.totalData.amountReturned }}&nbsp;&#8381;</td>
            </tr>
            <tr>
              <td>Всего з/п (предварительный рассчёт)</td>
              <td>{{ props.totalData.preliminaryAmount }}&nbsp;&#8381;</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>