<template>
  <div class="card">
    <div class="card-body">
      <router-link :to="{ name: 'BalancesPage' }" class="">
        Балансы водителей
      </router-link>
      |
      <router-link :to="{ name: 'TariffPage' }" class="">
        Тарифы
      </router-link>
      |
      <router-link :to="{ name: 'DriverCalculationPage' }" class="">
        Расчёт ЗП за период
      </router-link>
      |
      <router-link :to="{ name: 'PeriodListPage' }" class="">
        Периоды
      </router-link>
      |
      <b>Настройки:</b>
       <router-link :to="{ name: 'SalaryWeekendPage' }">
         Выходные дни</router-link>,
      <router-link :to="{ name: 'DriversNotifySettingPage' }">
        Уведомления</router-link>
    </div>
  </div>
</template>
<script setup>
</script>