<script setup>


import {useUserStore} from "@/store";
import {computed, onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";

import {useToast} from 'vue-toastification';

const toast = useToast();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);

const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function (error) {
    showErrors(error.response)
  });
}

const waterCompanyId = ref(1);
const showAddTariffForm = ref(false);
const newTariffName = ref('')
const tariffs = ref([])

const getAllTariffs = (wcId) => {
  api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/additional-tariffs?waterCompanyId=' + wcId).then(function (response) {
    tariffs.value = response.data.data
  }).catch(function (error) {
    showErrors(error)
  });
}
const createTariff = (wcId) => {
  api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/additional-tariff?waterCompanyId=' + wcId,
      {name: newTariffName.value, salaryFee: 0})
      .then(function () {
        toast.success("Тариф создан")
        showAddTariffForm.value = false
        newTariffName.value = ''
        getAllTariffs(wcId)
      })
      .catch(function (response) {
        showErrors(response)
      });
}
onBeforeMount(() => {
  checkAuth();
  getAllTariffs(waterCompanyId.value)
});
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Дополнительные тарифы</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'DriverSalaryPage'}">Заработная плата</router-link>
              </li>
              <li class="breadcrumb-item active">Водители и экспедиторы</li>
            </ol>
          </div>

        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-title">
            <div class="d-flex col-12">
              <div class="form-group col-md-4"><label for="wcid" class="col-form-label">Компания</label>
                <select id="wcid" name="wcid" class="form-control" autocomplete="off" v-model="waterCompanyId"
                        @change="getAllTariffs(waterCompanyId)">
                  <option :value=1 selected="">Хваловские Воды (С-Пб)</option>
                  <option :value=2>Хваловские Воды (Мск)</option>
                </select>
              </div>
            </div>
            <div class="form-group col-md-4">
              <button class="btn btn-primary waves-effect waves-light" @click="showAddTariffForm = !showAddTariffForm">
                Добавить
                тариф
              </button>
            </div>
          </div>
        </div>
        <div class="card card-primary card-outline" v-if="showAddTariffForm">
          <div class="card-body">
            <div class="card-header"><h2> Добавление тарифа </h2></div>
            <table class="table table-sm">
              <tbody>
              <tr>
                <td class="text-right" style="width: 20px">Название</td>
                <td><input type="text" class="form-control" v-model="newTariffName"></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <button class="btn btn-primary" @click="createTariff(waterCompanyId)">Сохранить</button>
            <button class="btn btn-default float-right" @click="showAddTariffForm = false; newTariffName = ''">
              Отменить
            </button>
          </div>
        </div>
      </div>
      <div class="card  card-outline " v-if="!showAddTariffForm">
        <div class="card-body">
          <table class="table table-striped table-sm table-bordered">
            <thead>
            <tr>
              <th style="text-align: center;">Список дополнительных тарифов</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="tariff in tariffs" :key="tariff.id">
              <td>
                <router-link :to="{ name: 'AdditionalTariffPage', params: { id: tariff.id }}">
                  {{ tariff.name }}
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>

  <!-- content off -->
</template>

<style scoped>

</style>