<script setup>
import {defineProps} from 'vue';

const props = defineProps({
  info: {
    name: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    interval: {
      type: String,
      required: true
    }
  }
});
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-auto">
         <b>{{props.info.name}}</b> {{props.info.date}} {{props.info.interval}}
        </div>
      </div>
    </div>
  </div>
</template>