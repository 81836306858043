<template>
  <div class="card">
    <div class="card-body">
     <span> <router-link :to="{
    name: 'StaffForManagerSheetsPage',
    params: { staffId: route.params.staffId }  }">  Маршрутные листы</router-link>
      |
     </span>
      <span> <router-link :to="{
    name: 'SendNotificationPage',
    params: { staffId: route.params.staffId }  }">  Уведомления</router-link>
      |
     </span>
      <span> <router-link :to="{
    name: 'StaffForManagerBalancePage',
    params: { staffId: route.params.staffId }  }">  Транзакции и Баланс</router-link>
        |
     </span>
      <span> <router-link :to="{
    name: 'StaffDebtPage',
    params: { staffId: route.params.staffId }  }">  Внебалансовый долг</router-link>
     </span>
    </div>
  </div>
</template>
<script setup>
import {useRoute} from "vue-router";

const route = useRoute();

</script>