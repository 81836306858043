vue
<script setup> import {useUserStore} from "@/store";
import {computed, onBeforeMount, ref} from "vue";
import Datapicker from "vue3-datepicker";
import ru from 'date-fns/locale/ru';
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import format from "date-fns/format";
import {VueSpinnerClock} from "vue3-spinners";
import {useToast} from 'vue-toastification';
import router from "@/router";
import SalaryMenu from "@/components/Salary/SalaryMenu.vue";
const toast = useToast();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function (error) {
    showErrors(error.response)
  });
}
const waterCompanyId = ref(1);

const dateFrom = ref(new Date());
const dateTo = ref(new Date());
const formattedDateFrom = computed(() => format(dateFrom.value, 'yyyy-MM-dd'));
const formattedDateTo = computed(() => format(dateTo.value, 'yyyy-MM-dd'));

const filters = ref(
    {
      'hasIP': true,
      'hasSZ': true,
      'hasNotIP': true,
      'hasNotSZ': true,
    }
);
const calculate = async () => {
  loading.value = true;
  try {
    await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/calculate-salary-period', {
      'waterCompanyId': waterCompanyId.value,
      'dateFrom': formattedDateFrom.value,
      'dateTo': formattedDateTo.value,
      'filters': filters.value
    });
    await sleep(2000);
    toast.success("Задача на рассчёт периода сформированна");
  } catch (error) {
    showErrors(error.response);
  } finally {
    loading.value = false;
    router.push({name: 'PeriodListPage'});

  }
};

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const loading = ref(false);
onBeforeMount(() => {
  checkAuth();
}); </script>
<template>
  <div class="content-wrapper" v-if="isAuthenticated"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Расчёт ЗП за период</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'DriverSalaryPage'}">Заработная плата</router-link>
              </li>
              <li class="breadcrumb-item active">Водители и экспедиторы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <SalaryMenu></SalaryMenu>
      </div>
    </section>
    <section class="content">
      <div class="vuespinner-container" v-if="loading">
        <div class="custom-spinner">
          <VueSpinnerClock size="125" color="darkslategray"/>
          <p class="spinner-text">Добавляем задачу в очередь...</p>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-title">
                <div class="form-group col-md-4"><label for="wcid" class="col-form-label">Компания</label>
                  <select id="wcid" name="wcid" class="form-control" autocomplete="off" v-model="waterCompanyId">
                    <option :value=1 selected="">Хваловские Воды (С-Пб)</option>
                    <option :value=2>Хваловские Воды (Мск)</option>
                  </select>
                </div>
              </div>
              <div class="card-title">
                <div class="form-group col-md-4"><label for="wcid" class="col-form-label">Фильтра</label>
                  <div class="row">
                  <div class="col-md-4">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" name="has_ip" v-model="filters.hasIP"> Есть ИП
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" name="has_ip" v-model="filters.hasSZ"> Есть СЗ
                      </label>
                    </div>
                  </div>
                  <div class=" col-md-4">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" name="has_ip" v-model="filters.hasNotIP"> Нет ИП
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" name="has_ip" v-model="filters.hasNotSZ"> Нет СЗ
                      </label>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              <div class="card-header"><h3 class="card-title"> Выберите период </h3></div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-2"><label for="dateStart" class="col-form-label">Дата с</label>
                    <Datapicker class="form-control" v-model="dateFrom" :locale="ru" input-format='dd.MM.yyyy'
                                typeable/>
                  </div>
                  <div class="form-group col-md-2"><label for="first-name" class="col-form-label">Дата по</label>
                    <Datapicker class="form-control" v-model="dateTo" :locale="ru" input-format='dd.MM.yyyy' typeable/>
                  </div>
                  <div class="form-group col-3 align-bottom"><label for="wcid"
                                                                    class="col-form-label col-12">&nbsp;</label>
                    <button type="submit" class="btn btn-success" @click="calculate()">Рассчитать</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>
<style scoped> .custom-spinner {
  z-index: 1000; /* Установите нужное значение z-index */
  position: fixed; /* Фиксированное позиционирование */
  top: 50%; /* Центрирование по вертикали */
  left: 50%; /* Центрирование по горизонтали */
  transform: translate(-50%, -50%); /* Центрирование по оси */
  background: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
  width: 100vw; /* Полная ширина экрана */
  height: 100vh; /* Полная высота экрана */
  display: flex;
  flex-direction: column; /* Вертикальное расположение элементов */
  justify-content: center;
  align-items: center;
}

.spinner-text {
  margin-top: 20px; /* Отступ сверху */
  font-size: 1.5em; /* Размер шрифта */
  color: darkslategray; /* Цвет текста */
} </style>