<script setup>
import {computed, onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useRoute} from "vue-router";
import fetchDataWithLocalStorage from "@/common/localStorage";
import axios from "axios";
import {useToast} from 'vue-toastification';

const toast = useToast();

const activeTab = ref('data');
const staff = ref({});
const ipList = ref({});

const optionsTemplate = ref([
  {id: 1, value: 'Яндекс.Доставка'},
  {id: 2, value: 'Яндекс.Драйв'},
  {id: 3, value: 'Делимобиль'},
]);

const staffAdditionalTariffs = ref([]);
const selectedAdditionalTariff = ref(false);

const getStaffAdditionalTariffs = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/staff-additional-tariff/' + route.params.id).then(function (response) {
    staffAdditionalTariffs.value = response.data.data
  }).catch(function (error) {
    showErrors(error)
  });
}

const additionalTariffs = ref([]);
const getAdditionalTariffs = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/additional-tariffs/?waterCompanyId=' + staff.value.waterCompanyId).then(function (response) {
    additionalTariffs.value = response.data.data
  }).catch(function (error) {
    showErrors(error)
  });
}

const addAdditionalTariff = (item) => {
  if (!item) {
    toast.error("Выберите тариф")
    return
  }
  staffAdditionalTariffs.value.push({
    additionalTariffId: item.id,
    name: item.name,
    staffId: route.params.id,
  })
}

const removeAdditionalTariff = (index) => {
  staffAdditionalTariffs.value.splice(index, 1);
}

const upsertStaffAdditionalTariffs = () => {
  api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/staff-additional-tariff/',
      {data: staffAdditionalTariffs.value}
  ).then(function () {
    toast.success("Данные обновлены")
  }).catch(function (error) {
    showErrors(error)
  });
}

const showAddTariffForm = ref(false);


const filteredOptions = computed(() => {
  return optionsTemplate.value.map(option => ({
    ...option,
    status: staff.value.options?.find?.(item => item.id === option.id) !== undefined
  }));
});

const getFilteredIds = () => {
  return filteredOptions.value.filter(option => option.status).map(option => option.id);
}


const getStaff = (id) => {
  api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/staff/' + id).then(function (response) {
    staff.value = response.data.data
    getWarehouses(staff.value.waterCompanyId);
    getIPList(staff.value.waterCompanyId);
    getStaffAdditionalTariffs();
    getAdditionalTariffs();
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
}

const updateStaff = () => {
  staff.value.phone= staff.value.phone.toString();
  axios.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/staff/' + staff.value.id + '/', {
    ...staff.value
  }, {
    headers: {'Authorization': 'Bearer ' + localStorage.access_token}
  }).then(({data}) => {
    if (data) {
      toast.success("Сотрудник обновлен");
    }
  }).catch(({response}) => {
    showErrors(response)
  });
}
const updateStaffOptions = () => {
  axios.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/staff/' + staff.value.id + '/options', {
    'options': getFilteredIds()
  }, {
    headers: {'Authorization': 'Bearer ' + localStorage.access_token}
  }).then(({data}) => {
    if (data) {
      toast.success("Отметки обновлены");
    }
  }).catch(({response}) => {
    showErrors(response)
  });
}

const getIPList = async (waterCompanyId) => {
  ipList.value = await fetchDataWithLocalStorage('ip-list.wcId:' + waterCompanyId, 1000 * 60 * 10, process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/staff/ip-list?waterCompanyId=' + waterCompanyId)
}
const route = useRoute();
const warehouses = ref([]);

const getWarehouses = async (id) => {
  warehouses.value = await fetchDataWithLocalStorage('warehouses.wcId:' + id, 1000 * 60 * 10, process.env.VUE_APP_GATE_URL + '/els/api/v1/warehouses?waterCompanyId=' + id)
}

onBeforeMount(() => {
  getStaff(route.params.id);
});

</script>

<template>
  <div class="content-wrapper">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Редактировать - {{ staff.firstName }} {{ staff.lastName }} {{ staff.patronymic }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Сотрудники</a></li>
              <li class="breadcrumb-item"><a href="#">Водители и экспедиторы</a></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->


    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header p-2">
            <ul class="nav nav-pills">
              <li class="nav-item"><a class="nav-link" :class="{'active':activeTab=='data'}" href="#"
                                      data-toggle="tab" @click="activeTab='data'">Данные</a></li>
              <li class="nav-item"><a class="nav-link" :class="{'active':activeTab=='settings'}" href="#"
                                      data-toggle="tab" @click="activeTab='settings'">Настройки</a></li>
              <li class="nav-item"><a class="nav-link" :class="{'active':activeTab=='tariffs'}" href="#"
                                      data-toggle="tab" @click="activeTab='tariffs'">Тарифы</a></li>
            </ul>
          </div>
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane" :class="{'active':activeTab=='data'}" id="data">
                <div class="card card-primary card-outline">
                  <div class="card-header">
                    <h3 class="card-title">
                      Данные сотрудника
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="form-group col-md-3">
                        <label for="last-name" class="col-form-label">Фамилия</label>
                        <input type="text" class="form-control " id="last-name" name="last_name"
                               v-model="staff.lastName">
                      </div>
                      <div class="form-group col-md-3">
                        <label for="first-name" class="col-form-label">Имя</label>
                        <input type="text" class="form-control " id="first-name" name="first_name"
                               v-model="staff.firstName">
                      </div>
                      <div class="form-group col-md-3">
                        <label for="patronymic" class="col-form-label">Отчество</label>
                        <input type="text" class="form-control " id="patronymic" name="patronymic"
                               v-model="staff.patronymic">
                      </div>
                      <div class="form-group col-md-3">
                        <label for="mark" class="col-form-label">Отметка</label>
                        <input type="text" class="form-control" id="mark" name="mark" v-model="staff.mark"
                               maxlength="20"
                               placeholder="до 20 символов">
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-2">
                        <label for="birthday" class="col-form-label">Дата рождения</label>
                        <input type="date" class="form-control datepicker ru_date " id="birthday" name="birthday"
                               v-model="staff.birthday"
                               style="width: 100% !important;">
                      </div>
                      <div class="form-group col-md-2">
                        <label for="phone-main" class="col-form-label">Телефон</label>
                        <input type="number" class="form-control " id="phone-main" name="phone_main"
                               v-model="staff.phone"
                               data-mask="+7 999 9999999" placeholder="+7 ___ _______">
                      </div>
                      <div class="form-group col-md-2">
                        <label for="code_1c" class="col-form-label">Код 1С</label>
                        <input type="text" class="form-control " id="code_1c" name="code_1c" v-model="staff.code1C">
                      </div>
                      <div class="form-group col-md-1">
                        <label for="code_1c" class="col-form-label">ID</label>
                        <input type="text" class="form-control" id="old_id_fake" name="old_id_fake" v-model="staff.id"
                               readonly="">
                      </div>
                      <div class="form-group col-md-1">
                        <label for="code_1c" class="col-form-label">old ID</label>
                        <input type="text" class="form-control" id="old_id_fake" name="old_id_fake"
                               v-model="staff.oldId"
                               readonly="">
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-10">
                        <label for="staff-description" class="col-form-label">Описание </label>
                        <textarea class="form-control" name="description" id="staff-description"
                                  v-model="staff.description"></textarea>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="card card-primary card-outline">
                  <div class="card-header">
                    <h3 class="card-title">
                      Паспорт сотрудника
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="form-group col-md-2">
                        <label for="passport-series" class="col-form-label">Серия</label>
                        <input type="text" class="form-control" id="passport-series" name="passport_series"
                               v-model="staff.passportSeries">
                      </div>
                      <div class="form-group col-md-2">
                        <label for="passport-number" class="col-form-label">Номер</label>
                        <input type="text" class="form-control" id="passport-number" name="passport_number"
                               v-model="staff.passportNumber">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="registration-address" class="col-form-label">Адрес регистрации</label>
                        <input type="text" class="form-control" id="registration-address" name="registration_address"
                               value="">
                      </div>
                      <div class="form-group col-auto">
                        <div class="form-check" style="padding-top: 43px;">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" name="passport_rf"
                                   v-model="staff.passportRF"> Паспорт РФ
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary" @click="updateStaff">Сохранить</button>
                </div>
              </div>
              <div class="tab-pane" id="settings" :class="{'active':activeTab=='settings'}">
                <div class="card card-primary card-outline">
                  <div class="card-header">
                    <h3 class="card-title">
                      Настройки
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="form-group col-md-2">
                        <label for="staff-type-id" class="col-form-label">Тип</label>
                        <select name="type_id" id="staff-type-id" class="form-control" v-model="staff.typeId">
                          <option :value=1>Водитель</option>
                          <option :value=2>Экспедитор</option>
                        </select>
                      </div>
                      <div class="form-group col-md-2">
                        <label for="staff-status-id" class="col-form-label">Статус</label>
                        <select name="status_id" id="staff-status-id" class="form-control" v-model="staff.statusId">
                          <option :value=1>Работает</option>
                          <option :value=2>Не работает</option>
                          <option :value=3 disabled>Заблокирован</option>
                        </select>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="water-company-id" class="col-form-label">Компания</label>
                        <select id="water-company-id" name="water_company_id" class="form-control " disabled
                                v-model="staff.waterCompanyId">
                          <option :value=1>Хваловские Воды (С-Пб)</option>
                          <option :value=2>Хваловские Воды (Мск)</option>
                        </select>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="default-warehouse-id" class="col-form-label">Склад по умолчанию</label>
                        <select id="default-warehouse-id" name="default_warehouse_id" class="form-control "
                                autocomplete="off" v-model="staff.defaultWarehouseId">
                          <option :value=0>Не выбран склад</option>
                          <option v-for="w in warehouses" :key="w.id" :value="w.id">
                            {{ w.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-2">
                        <label for="date-of-employment" class="col-form-label">Трудоустроен с</label>
                        <input type="text" class="form-control datepicker ru_date " id="date-of-employment"
                               name="date_of_employment" v-model="staff.dateOfEmployment" placeholder="05.02.2024"
                               style="width: 100% !important;">
                      </div>
                      <div class="form-group col-md-4">
                        <label for="staff_ip_id" class="col-form-label">Работает под ИП</label>
                        <select id="staff_ip_id" name="staff_ip_id" class="form-control" autocomplete="off"
                                :disabled="staff.hasIP"
                                v-model="staff.ipId">
                          <option value="0" selected="">Выбрать</option>
                          <option v-for="ip in ipList" :key="ip.id" :value="ip.id">
                            {{ ip.name }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="work_with_app" class="col-form-label">Может работать с мобильным приложением</label>
                        <select id="work_with_app" name="work_with_app" class="form-control" autocomplete="off"
                                v-model="staff.workWithApp">
                          <option :value=true>Да</option>
                          <option :value=false>Нет</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" name="has_ip" v-model="staff.hasIP"> Есть ИП
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" name="has_ip" v-model="staff.hasSZ"> Есть СЗ
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" name="has_lk" v-model="staff.hasAccessToLK"> Есть доступ в ЛК
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-2">
                        <label for="date-of-employment" class="col-form-label">Отметки</label>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-auto" v-for="option in filteredOptions" :key="option.id">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" v-model="option.status"> {{ option.value }}
                          </label>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary" @click="updateStaff(); updateStaffOptions()">
                      Сохранить
                    </button>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tariffs " :class="{'active':activeTab=='tariffs'}">
                <p>Вы можете указать дополнительные тарифы на нужные диапазоны дат. Если на данную дату не указан ни
                  один доп. тариф, то будет применяться базовый тариф.</p>
                <button class="btn btn-primary waves-effect waves-light"
                        @click="showAddTariffForm = !showAddTariffForm"> Добавить тариф
                </button>
                <br><br>
                <div class="card card-primary card-outline" v-if="showAddTariffForm">
                  <div class="card-body">
                    <div class="card-header"><h2> Выбор тарифа </h2></div>
                    <table class="table table-sm">
                      <tbody>
                      <tr>
                        <td class="text-right" style="width: 20px;">Название</td>
                        <td>
                          <select class="form-control" autocomplete="off" v-model="selectedAdditionalTariff">
                            <option v-for="w in additionalTariffs" :key="w.id" :value="w">
                              {{ w.name }}
                            </option>
                          </select>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-primary" @click="addAdditionalTariff(selectedAdditionalTariff)">Добавить
                    </button>
                    <button class="btn btn-default float-right" @click="showAddTariffForm = false"> Скрыть</button>
                  </div>
                </div>

                <div class="card card-primary card-outline card-body">
                  <div class="form-row">
                    <table class="table table-striped table-sm table-bordered">
                      <thead>
                      <tr>
                        <th style="text-align: center; width: 60%">Тариф</th>
                        <th style="text-align: center;">С</th>
                        <th style="text-align: center;">До</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in staffAdditionalTariffs" :key="index">
                        <td style="text-align: center;">{{ item.name }}</td>
                        <td style="text-align: center;">
                          <input type="date" class="form-control datepicker ru_date " id="birthday" name="birthday"
                                 v-model="item.dateFrom"
                                 style="width: 100% !important;">
                        </td>
                        <td style="text-align: center;">
                          <input type="date" class="form-control datepicker ru_date " id="birthday" name="birthday"
                                 v-model="item.dateTo"
                                 style="width: 100% !important;"></td>
                        <td style="text-align: center;">
                          <button class="btn btn-danger btn-sm" @click="removeAdditionalTariff(item)">Удалить</button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <button class="btn btn-primary waves-effect waves-light" @click="upsertStaffAdditionalTariffs">
                      Сохранить
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>

</template>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>