<script setup>


import {useUserStore} from "@/store";
import {computed, onBeforeMount, ref, nextTick} from "vue";
import {showErrors} from "@/common/showErrors";
import api from "@/common/api";
import {useRoute} from "vue-router";
import {useToast} from "vue-toastification";

const route = useRoute();
const toast = useToast();


const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);


const statement = ref(null);
const visibleDeductionAddForm = ref(false);
const visibleDeductionEditForm = ref(false);
const staffs = ref([]);

const deductionDTO = ref({
  staffId: null,
  deductionDescription: null,
  deductionAmount: null,
  statementId: route.params.id,
});

const editDeductionDTO = ref({
  deductionId: null,
  staffName: null,
  deductionDescription: null,
  deductionAmount: null,
  statementId: route.params.id,
});
const bottomElement = ref(null);
const scrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
};
const showDeductionEditForm = (deductionId, staffName, deductionAmount, deductionDescription) => {
  visibleDeductionEditForm.value = true
  editDeductionDTO.value.deductionId = deductionId
  editDeductionDTO.value.staffName = staffName
  editDeductionDTO.value.deductionAmount = deductionAmount
  editDeductionDTO.value.deductionDescription = deductionDescription
  nextTick(() => {
    scrollToBottom()
  })
}


const GetStatement = async (id) => {
  await api.get(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement/' + id).then(function (response) {
    statement.value = response.data.data;
    // Extract unique staffs from statementTransactions
    const uniqueStaffs = new Map();
    statement.value.statementTransactions.forEach(transaction => {
      if (!uniqueStaffs.has(transaction.staffId)) {
        uniqueStaffs.set(transaction.staffId, {
          id: transaction.staffId,
          staffName: transaction.staffName
        });
      }
    });

    // Convert Map to array and assign to staffs
    staffs.value = Array.from(uniqueStaffs.values());
  }).catch(function (error) {
    showErrors(error.response);
  });
}

const addDeduction = async () => {
  api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement/deduction', deductionDTO.value).then(function () {
    GetStatement(route.params.id);
    toast.success("Вычет добавлен");
    visibleDeductionAddForm.value = false;
    deductionDTO.value.staffId = null;
    deductionDTO.value.deductionDescription = null;
    deductionDTO.value.deductionAmount = null;

  }).catch(function (error) {
    showErrors(error.response);

  })
}

const deleteDeduction = (id) => {
  api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement/deduction/delete', {
    deductionId: id
  }).then(function () {
    GetStatement(route.params.id);
    toast.success("Вычет добавлен");
    visibleDeductionAddForm.value = false;
  }).catch(function (error) {
    showErrors(error.response);
  })
}

const updateDeduction = () => {
  api.put(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement/deduction/edit', editDeductionDTO.value).then(function () {
    toast.success("Вычет обновлён");
    visibleDeductionEditForm.value = false;
    GetStatement(route.params.id);

  }).catch(function (error) {
    showErrors(error.response);
  })
}

onBeforeMount(() => {
  GetStatement(route.params.id);
});
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <H1>Редактирование ведомости от {{ statement?.date }} склад {{ statement?.warehouse?.name }}</H1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}">Заказы</router-link>
              </li>
              <li class="breadcrumb-item active">Доставленные</li>
            </ol>
          </div>

        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-title">
            <h2 class="card-title">Транзакции</h2>
          </div>
          <div class="card-body">
            <div class="form-row">
              <table class="table table-striped table-sm">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Имя водителя</th>
                  <th>Тип денег</th>
                  <th>Сумма</th>
                  <th>Время транзакции</th>
                  <th>Причина</th>

                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in statement?.statementTransactions" :key="index">
                  <td>{{ item.transactionId }}</td>
                  <td> {{ item.staffName }}</td>
                  <td> {{ item.moneyType == 'cash' ? 'Бумажные' : 'Мелочь' }}</td>
                  <td> {{ item.amount }}</td>
                  <td> {{ item.transactionDate }}</td>
                  <td> {{ item.reason }}</td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="form-row">
              <button class="btn btn-info" @click="visibleDeductionAddForm=true">Добавить вычет</button>
            </div>
          </div>
        </div>

        <div class="card" v-if="visibleDeductionAddForm">
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-3"><label for="wcid" class="col-form-label">Сотрудник</label>
                <select id="wcid" name="wcid" class="form-control" autocomplete="off" v-model="deductionDTO.staffId">
                  <option v-for="staff in staffs" :key="staff.id" :value="staff.id">{{ staff.staffName }}</option>
                </select>
              </div>
              <div class="form-group col-md-3"><label class="col-form-label">Сумма вычета</label>
                <input type="number" class="form-control" v-model="deductionDTO.deductionAmount">
              </div>
              <div class="form-group col-md-6"><label class="col-form-label">Описание</label>
                <input type="text" class="form-control" v-model="deductionDTO.deductionDescription">
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-info" @click="addDeduction()">Сохранить</button>
              <button type="submit" class="btn btn-default float-right" @click="visibleDeductionAddForm=false">Отмена
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <h2 class="card-title">Вычеты</h2>
          </div>
          <div class="card-body">
            <div class="form-row">
              <table class="table table-striped table-sm">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Имя водителя</th>
                  <th>Сумма</th>
                  <th>Комментарий</th>
                  <th>Редактировать</th>
                  <th>Удалить</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in statement?.statementDeductions" :key="index">
                  <td>{{ item.id }}</td>
                  <td> {{ item.staffName }}</td>
                  <td> {{ item.amount }}</td>
                  <td> {{ item.description }}</td>
                  <td>
                    <button class="btn-info"
                            @click="showDeductionEditForm(item.id, item.staffName, item.amount,item.description );">
                      Редактировать
                    </button>
                  </td>
                  <td>
                    <button class="btn-danger" @click="deleteDeduction(item.id)">Удалить</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card" v-if="visibleDeductionEditForm">
            <div class="card-body">
              <div>
                <h3>Редактирование</h3>
              </div>
              <div class="form-row">
                <div class="form-group col-md-3"><label for="wcid" class="col-form-label">Сотрудник</label>
                  <input type="text" class="form-control" v-model="editDeductionDTO.staffName" readonly>
                </div>
                <div class="form-group col-md-3"><label class="col-form-label">Сумма вычета</label>
                  <input type="number" class="form-control" v-model="editDeductionDTO.deductionAmount">
                </div>
                <div class="form-group col-md-6"><label class="col-form-label">Описание</label>
                  <input type="text" class="form-control" v-model="editDeductionDTO.deductionDescription">
                </div>
              </div>
              <div class="card-footer">
                <div ref="bottomElement"></div>
                <button type="submit" class="btn btn-info" @click="updateDeduction()">Обновить</button>
                <button type="submit" class="btn btn-default float-right" @click="visibleDeductionEditForm=false">Отмена
                </button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
  </div>

  <!-- content off -->
</template>

<style scoped>

</style>