<script setup>
import {computed, onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import router from "@/router";
import {useToast} from "vue-toastification";
import {useRoute} from "vue-router";
import {useUserStore} from "@/store";
import DepartmentPageMenu from "@/components/Departments/DepartmentMenu.vue";

const department = ref({
  name: '',
  waterCompaniesIds: [],
  ownerId: null,
  owner: null
})

const users = ref([]);
const availablePermissions = ref([]);
const departmentPermissions = ref([]);
const targetPermissionIdForAdd = ref(null);
const targetPermissionIdForDelete = ref(null);

const cities = ref([
  {id: 2, name: 'Москва'}, {id: 1, name: 'Санкт-Петербург'}
]);


const toast = useToast();
const updateDepartment = (id) => {
  api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + id, department.value).then(function () {
    toast.success("Отдел обновлён")
    router.push({name: 'DepartmentsPage'})
  }).catch(function (error) {
    showErrors(error.response)
  })
}
const getDepartment = (id) => {
  api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + id).then(function (response) {
    department.value.name = response.data.data.name
    department.value.waterCompaniesIds = response.data.data.water_companies.map(company => company.water_company_id);
    department.value.ownerId = response.data.data.owner_id
    department.value.owner = response.data.data.owner

  }).catch(function (error) {
    showErrors(error.response)
  })
}

const getDepartmentPermissions = async (id) => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + id + '/permissions').then(function (response) {
    departmentPermissions.value = response.data.data
    getAvailablePermissions()
  }).catch(function (error) {
    showErrors(error)
  })
}

const getAvailablePermissions = async () => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/permissions').then(function (response) {
    availablePermissions.value = response.data.data.filter(permission => !departmentPermissions.value.some(deptPerm => deptPerm.id === permission.id));
  }).catch(function (error) {
    showErrors(error)
  })
}

const addPermissionToDepartment = async (id) => {
  await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + route.params.id + '/permission/' + id).then(function () {
    toast.success("Разрешение добавлено");
    getDepartmentPermissions(route.params.id);
  }).catch(function (error) {
        showErrors(error)
      }
  )
}

const deletePermissionFromDepartment = async (id) => {
  await api.delete(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + route.params.id + '/permission/' + id).then(function () {
    toast.success("Разрешение удалено");
    getDepartmentPermissions(route.params.id);
  }).catch(function (error) {
    showErrors(error)
  })
}
const getUsersWithoutOwner = async () => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/users-without-owner').then(function (response) {
    users.value = response.data.data
  }).catch(function (error) {
    showErrors(error)
  })
}

const route = useRoute();
const store = useUserStore();
const userRole = computed(() => store.user.role);
onBeforeMount(() => {
  getDepartment(route.params.id);
  getUsersWithoutOwner();
  getDepartmentPermissions(route.params.id);



});
</script>
<template>
  <div class="content-wrapper"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Редактировать отдел {{department.name}}</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Отделы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <DepartmentPageMenu></DepartmentPageMenu>
      </div>
      <div class="container-fluid">
        <div class="row" v-if="userRole.includes('admin')">
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Название</label>
                      <input v-model="department.name" type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Город</label>
                      <select multiple class="form-control" v-model="department.waterCompaniesIds">
                        <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
                      </select>
                    </div>
                  </form>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-info" @click="updateDepartment(route.params.id)">Обновить
                    </button>
                    <button type="submit" class="btn btn-default float-right"
                            @click="router.push({name: 'DepartmentsPage'})">Отмена
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Руководитель</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" v-model="department.ownerId">
                        <option v-for="user in users" :key="user.id" :value="user.id"
                                :selected="department.ownerId === user.id">
                          {{ user.name }}
                        </option>
                        <option v-if="department.owner" :key="department.owner.id" :value="department.owner.id"
                                :selected="department.ownerId === department.owner.id">
                          {{ department.owner.name }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="userRole.includes('admin')">
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Список разрешений</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" size="25" v-model="targetPermissionIdForAdd">
                        <option v-for="permission in availablePermissions" :key="permission.id" :value="permission.id">
                          {{ permission.name }} | {{ permission.description}}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info" @click="addPermissionToDepartment(targetPermissionIdForAdd)">Добавить в
                    отдел
                  </button>
                </div>
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info" @click="deletePermissionFromDepartment(targetPermissionIdForDelete)">
                    Удалить из отдела
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Разрешения в отделе</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" v-model="targetPermissionIdForDelete" size="25">
                        <option v-for="permission in departmentPermissions" :key="permission.id" :value="permission.id">
                          {{ permission.name }} | {{ permission.description}}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section> <!-- content off --> </div>
</template>
<style scoped> .custom-spinner {
  z-index: 1000; /* Установите нужное значение z-index */
  position: fixed; /* Фиксированное позиционирование */
  top: 50%; /* Центрирование по вертикали */
  left: 50%; /* Центрирование по горизонтали */
  transform: translate(-50%, -50%); /* Центрирование по оси */
  background: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
  width: 100vw; /* Полная ширина экрана */
  height: 100vh; /* Полная высота экрана */
  display: flex;
  flex-direction: column; /* Вертикальное расположение элементов */
  justify-content: center;
  align-items: center;
}

</style>