import {useToast} from 'vue-toastification';

const toast = useToast();

export function showErrors(response) {
    let timeToLifeMs = 12000;

    if (response.response){
        response = response.response
    }

    if (response.status == 404) {
        toast.error("Страница не найдена",
            {
                timeout: timeToLifeMs
            });
        return
    }

    if (!response?.data) {
        toast.error("Неизвестная ошибка",
            {
                timeout: timeToLifeMs
            });
        return
    }

    if (response.data.errors && response.data.errors.title) {
        toast.error(response.data.errors.title,
            {
                timeout: timeToLifeMs
            });
        return
    }

    if (response.data.errors) {
        let errors = response.data.errors
        for (const key in errors) {
            if (Object.hasOwnProperty.call(errors, key)) {
                const error = errors[key][0];
                toast.error(error,
                    {
                        timeout: timeToLifeMs
                    });
            }
        }
        return;
    }

    if (response.data.validation) {
        let errors = response.data.validation
        for (const key in errors) {
            if (Object.hasOwnProperty.call(errors, key)) {
                const error = errors[key];
                toast.error(error.message,
                    {
                        timeout: timeToLifeMs
                    });
            }
        }
        return;
    }

    if (response.data.message && response.data.message.body) {
        toast.error(response.data.message.body,
            {
                timeout: timeToLifeMs
            });
        console.log('Непредвиденная ошибка!', response.data);
        return;
    }

    if (response.data.message) {
        toast.error('Возникла ошибка.',
            {
                timeout: timeToLifeMs
            });
        console.log('Ошибка!', response.data);
        return;
    }
}


