<script setup>
import {onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {} from "@/common/showErrors";
import {useRoute} from "vue-router";
import {showErrors} from "@/common/showErrors";
import StaffForManagerMenu from "@/components/Staff/StaffForManagerMenu.vue";
import {useToast} from "vue-toastification";


const toast = useToast();


const route = useRoute();
const staff = ref(null);
const getStaff = async (staffId) => {
  try {
    const {data} =await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/staff/' + staffId)
    staff.value = data.data
  } catch (e) {
    showErrors(e)
  }
}

const sendNotification = async () => {
  try {
    await api.get(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/driver-balance/notification/' + route.params.staffId)
    toast.success('Уведомление отправлено')
  } catch (e) {
    showErrors(e)
  }
}

onBeforeMount(() => {
  getStaff(route.params.staffId)
});
</script>

<template>
  <div class="content-wrapper">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-9">
            <H1>Отправка уведомления о задолженности для водителя {{ staff?.firstName }} {{ staff?.lastName }} {{ staff?.patronymic }}</H1>
          </div>
          <div class="col-sm-3">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <StaffForManagerMenu></StaffForManagerMenu>
      </div>
      <div class="container-fluid">
        <div class="row">
          <button class="btn btn-info" @click="sendNotification()">Отправить уведомление о задолженности</button>
        </div>
      </div>
    </section>
  </div>
  <!-- content off -->
</template>

<style scoped>

</style>