<script setup>
import {onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import router from "@/router";
import {useToast} from "vue-toastification";
import {useRoute} from "vue-router";

const name = ref(null)
const toast = useToast();
const route = useRoute();

const addPost = (id) => {
  api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + id + '/post',
      {'name': name.value}).then(function () {
    toast.success("Должность добавлена")
    router.push({name: 'DepartmentPostsPage'})
  }).catch(function (error) {
    showErrors(error.response)
  })
}
onBeforeMount(() => {
});
</script>
<template>
  <div class="content-wrapper"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Добавить новую должность</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Отделы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Название</label>
                      <input v-model="name" type="text" class="form-control">
                    </div>
                  </form>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-info" @click="addPost(route.params.id)">Добавить</button>
                    <button type="submit" class="btn btn-default float-right"
                            @click="router.push({name: 'DepartmentPostsPage'})">Отмена
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>