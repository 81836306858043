<script setup>
import { filterFieldsBySearchBy, searchByOptions } from '@/lib/auxiliary/customerSearch'
import UIButton from "../UI/UIButton.vue";
import UIInput from "../UI/UIInput.vue";
import UISelect from "../UI/UISelect.vue";
import { useParamsStore } from "@/store/params";
import { computed, ref, defineEmits, defineProps } from "vue";

const emits = defineEmits(['search', 'update:modelValue', 'new-error'])
const props = defineProps({
    modelValue: { type: Object, required: true },
    isLoadingSearchBtn: { type: Boolean, required: false, default: () => false },
    errors: { type: Array, required: false, default: () => [] }
})

const searchBy = ref('name')

const companyCities = computed(() => useParamsStore().cities)

const updateHandler = (field, newValue) => {
    const newModelValue = { ...props.modelValue }
    newModelValue[field] = newValue
    emits('update:modelValue', newModelValue)
}

const defaultFilterValue = ref({
  phone: '',
  last_name: '',
  first_name: '',
  patronymic: '',
  company: '',
  street: '',
  water_company_id: 1
})
const setDefaultFilters = () => {
    emits('update:modelValue', defaultFilterValue.value)
}

const isEmptyFilter = computed(() => {
    const copyFilterForm = structuredClone(props.modelValue)
    copyFilterForm.water_company_id = 0
    for(const value of Object.values(copyFilterForm)) {
        if (value) { 
            return false
        }
    }
    return true
})

const isDisabledSubmitBtn = computed(() => {
    return props.isLoadingSearchBtn || !!props.errors.length || isEmptyFilter.value
})
</script>

<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title"><i class="fas fa-search mr-2"></i>Найти клиента</h3>
        </div>
        <div class="card-body">
            <div class="form-row">
                <UISelect label="Город" :modelValue="modelValue.water_company_id" :options="companyCities"
                    :mapOptions="(city) => ({ title: city.short_name, value: city.id })"
                    @update:modelValue="updateHandler('water_company_id', $event)" />
                <UISelect label="Поиск по" v-model="searchBy" :options="searchByOptions" @update:modelValue="setDefaultFilters" />
                <UIInput v-for="field in filterFieldsBySearchBy[searchBy]" :key="field.title"
                    :modelValue="props.modelValue[field.model]" :minLength="field.minLength" validate
                    @update:modelValue="updateHandler(field.model, $event)" :label="field.title"
                    @new-error="emits('new-error', $event)" />
                <div class="form-group col-md-2 col-xl-2">
                    <label for="wcId" class="col-form-label">&nbsp;</label>
                    <UIButton type="submit" :isLoading="isLoadingSearchBtn" :disabled="isDisabledSubmitBtn"
                        @click="emits('search', searchBy)">Найти</UIButton>
                </div>
            </div>
        </div>
    </div>
</template>