<script setup>
import {useUserStore} from "@/store";
import {computed, onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";

const toast = useToast();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);

const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function (error) {
    showErrors(error.response)
  });
}
const workDayTariffIsReady = ref(false);
const weekendTariffIsReady = ref(false);
const workDayTariff = ref({
  forwarderTariffBaseOptions: {},
  forwarderTariffDeliveryRequestOptionsArray: [{}],
  forwarderTariffOrderOptionsArray: {},
});
const weekendTariff = ref({
  forwarderTariffBaseOptions: {},
  forwarderTariffDeliveryRequestOptionsArray: [{}],
  forwarderTariffOrderOptionsArray: {},
});
const waterCompanyId = ref(1);

const getWorkDayTariff = (wcId) => {
  api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/default-forwarder-tariff?isWeekend=0&waterCompanyId=' + wcId)
      .then(function (response) {
        workDayTariff.value = response.data.data
        workDayTariffIsReady.value = true
      })
      .catch(function (error) {

        showErrors(error.response)
      });
}

const getWeekendDayTariff = (wcId) => {
  api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/default-forwarder-tariff?isWeekend=1&waterCompanyId=' + wcId)
      .then(function (response) {
        weekendTariff.value = response.data.data
        weekendTariffIsReady.value = true
      })
      .catch(function (error) {
        workDayTariff.value = {
          forwarderTariffBaseOptions: {},
          forwarderTariffDeliveryRequestOptionsArray: {},
          forwarderTariffOrderOptionsArray: {},
        }
        showErrors(error.response)
      });
}

const updateBaseOptionsWorkDay = async (wcId) => {
  try {
    await api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/default-forwarder-tariff-base-options',
        {
          "waterCompanyId": wcId,
          "isWeekend": 0,
          "phoneFee": workDayTariff.value.forwarderTariffBaseOptions.phoneFee,
          "salaryFee": workDayTariff.value.forwarderTariffBaseOptions.salaryFee
        }
    );
    return true;
  } catch (error) {
    showErrors(error.response);
    return false;
  }
}

const updateBaseOptionsWeekend = async (wcId) => {
  try {
    await api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/default-forwarder-tariff-base-options',
        {
          "waterCompanyId": wcId,
          "isWeekend": 1,
          "phoneFee": weekendTariff.value.forwarderTariffBaseOptions.phoneFee,
          "salaryFee": weekendTariff.value.forwarderTariffBaseOptions.salaryFee
        }
    );
    return true;
  } catch (error) {
    showErrors(error.response);
    return false;
  }
}

const updateDeliveryRequestOptionsWorkDay = async (wcId) => {
  try {
    await api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/default-forwarder-tariff-delivery-request-options',
        {
          "waterCompanyId": wcId,
          "forwarderTariffDeliveryRequestOptionsArray": workDayTariff.value.forwarderTariffDeliveryRequestOptionsArray,
        }
    );
    return true;
  } catch (error) {
    showErrors(error.response);
    return false;
  }
}

const updateDeliveryRequestOptionsWeekend = async (wcId) => {
  try {
    await api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/default-forwarder-tariff-delivery-request-options',
        {
          "waterCompanyId": wcId,
          "forwarderTariffDeliveryRequestOptionsArray": weekendTariff.value.forwarderTariffDeliveryRequestOptionsArray,
        }
    );
    return true;
  } catch (error) {
    showErrors(error.response);
    return false;
  }
}

const updateOrderOptionsWorkDay = async (wcId) => {
  try {
    await api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/default-forwarder-tariff-order-options',
        {
          "waterCompanyId": wcId,
          "forwarderTariffOrderOptionsArray": workDayTariff.value.forwarderTariffOrderOptionsArray,
        }
    );
    return true;
  } catch (error) {
    showErrors(error.response);
    return false;
  }
}

const updateOrderOptionsWeekend = async (wcId) => {
  try {
    await api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/default-forwarder-tariff-order-options',
        {
          "waterCompanyId": wcId,
          "forwarderTariffOrderOptionsArray": weekendTariff.value.forwarderTariffOrderOptionsArray,
        }
    );
    return true;
  } catch (error) {
    showErrors(error.response);
    return false;
  }
}


const updateAll = async (wcId) => {
  let ok, ok2, ok3, ok4, ok5, ok6;
  ok = await updateBaseOptionsWorkDay(wcId)
  ok2 = await updateBaseOptionsWeekend(wcId)
  ok3 = await updateDeliveryRequestOptionsWorkDay(wcId)
  ok4 = await updateDeliveryRequestOptionsWeekend(wcId)
  ok5 = await updateOrderOptionsWorkDay(wcId)
  ok6 = await updateOrderOptionsWeekend(wcId)
  if (!ok || !ok2 || !ok3 || !ok4 || !ok5 || !ok6) {
    toast.error("Не смогли обновить")
  } else {
    toast.success("Тариф успешно обновлён")
  }
}
onBeforeMount(() => {
  checkAuth();
  getWorkDayTariff(waterCompanyId.value);
  getWeekendDayTariff(waterCompanyId.value);
});
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Базовый Тариф для экспедиторов</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'DriverSalaryPage'}">Заработная плата</router-link>
              </li>
              <li class="breadcrumb-item active">Водители и экспедиторы</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content" v-if="workDayTariffIsReady && weekendTariffIsReady">
      <div class="container-fluid">
        <div class="card">

          <div class="card-title">
            <div class="form-group col-md-4"><label for="wcid" class="col-form-label">Компания</label>
              <select id="wcid" name="wcid" class="form-control" autocomplete="off" v-model="waterCompanyId"
                      @change="getWorkDayTariff(waterCompanyId); getWeekendDayTariff(waterCompanyId); ">
                <option :value=1 selected="">Хваловские Воды (С-Пб)</option>
                <option :value=2>Хваловские Воды (Мск)</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="tab-content">
                <div class="row">
                  <div class="col-md-6">
                    <h1 class="m-0">Будние дни</h1>
                    <div class="card card-primary card-outline">
                      <div class="card-header">
                        <h2>
                          Основные
                        </h2>
                      </div>
                      <table class="table table-sm table-bordered">
                        <tbody>
                        <tr>
                          <td class="text-right ">оклад</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffBaseOptions.salaryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right ">телефон</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffBaseOptions.phoneFee">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="card card-primary card-outline">
                      <div class="card-header">
                        <h2>
                          Заявки
                        </h2>
                      </div>
                      <table class="table table-sm table-bordered">
                        <thead>
                        <tr>
                          <th colspan="2">заявки - утро (будни)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">Тариф за выход</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 0 : 4].shiftFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 60</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 0 : 4].less60BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 80</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 0 : 4].less80BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 0 : 4].less100BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок более 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 0 : 4].moreOrEqual100BottlesFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заявки - утро/день (будни)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">Тариф за выход</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 1 : 5].shiftFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 60</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 1 : 5].less60BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 80</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 1 : 5].less80BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 1 : 5].less100BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок более 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 1 : 5].moreOrEqual100BottlesFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заявки - день (будни)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">Тариф за выход</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 2 : 6].shiftFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 60</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 2 : 6].less60BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 80</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 2 : 6].less80BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 2 : 6].less100BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок более 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 2 : 6].moreOrEqual100BottlesFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заявки - вечер (будни)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">Тариф за выход</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 3 : 7].shiftFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 60</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 3 : 7].less60BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 80</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 3 : 7].less80BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 3 : 7].less100BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок более 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 3 : 7].moreOrEqual100BottlesFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заявки - быстрая доставка (будни)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">Тариф за выход</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 8 : 9].shiftFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 60</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 8 : 9].less60BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 80</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 8 : 9].less80BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 8 : 9].less100BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок более 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 8 : 9].moreOrEqual100BottlesFee">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="card card-primary card-outline">
                      <div class="card-header">
                        <h2>
                          Заказы
                        </h2>
                      </div>

                      <table class="table table-sm table-bordered">
                        <thead>
                        <tr>
                          <th colspan="2">заказы - утро (будни)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">1 точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].onePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].oneBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 морс</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].oneFruitDrinkFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут 6л</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].one6LiterBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].oneCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные точки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].undeliveredPointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные бутылки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].undeliveredBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленный кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].undeliveredCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].pickUpBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].pickUpBottlePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">платная доставка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].paidDeliveryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">доплата за центр</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].centerBonusFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заказы - утро/день (будни)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">1 точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].onePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].oneBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 морс</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].oneFruitDrinkFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут 6л</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].one6LiterBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].oneCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные точки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].undeliveredPointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные бутылки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].undeliveredBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленный кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].undeliveredCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].pickUpBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].pickUpBottlePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">платная доставка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].paidDeliveryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">доплата за центр</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].centerBonusFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заказы - день (будни)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">1 точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].onePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].oneBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 морс</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].oneFruitDrinkFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут 6л</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].one6LiterBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].oneCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные точки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].undeliveredPointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные бутылки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].undeliveredBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленный кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].undeliveredCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].pickUpBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].pickUpBottlePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">платная доставка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].paidDeliveryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">доплата за центр</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].centerBonusFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заказы - вечер (будни)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">1 точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].onePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].oneBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 морс</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].oneFruitDrinkFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут 6л</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].one6LiterBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].oneCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные точки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].undeliveredPointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные бутылки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].undeliveredBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленный кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].undeliveredCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].pickUpBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].pickUpBottlePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">платная доставка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].paidDeliveryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">доплата за центр</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].centerBonusFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заказы - быстрая доставка (будни)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">1 точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].onePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].oneBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 морс</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].oneFruitDrinkFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут 6л</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].one6LiterBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].oneCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные точки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].undeliveredPointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные бутылки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].undeliveredBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленный кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].undeliveredCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].pickUpBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].pickUpBottlePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">платная доставка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].paidDeliveryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">доплата за центр</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="workDayTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].centerBonusFee">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <h1 class="m-0">Выходные дни</h1>
                    <div class="card card-primary card-outline">
                      <div class="card-header">
                        <h2>
                          Основные
                        </h2>
                      </div>
                      <table class="table table-sm table-bordered">
                        <tbody>
                        <tr>
                          <td class="text-right ">оклад</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffBaseOptions.salaryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right ">телефон</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffBaseOptions.phoneFee">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="card card-primary card-outline">
                      <div class="card-header">
                        <h2>
                          Заявки
                        </h2>
                      </div>
                      <table class="table table-sm table-bordered">
                        <thead>
                        <tr>
                          <th colspan="2">заявки - утро (выходные)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">Тариф за выход</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 0 : 4].shiftFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 60</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 0 : 4].less60BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 80</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 0 : 4].less80BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 0 : 4].less100BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок более 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 0 : 4].moreOrEqual100BottlesFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заявки - утро/день (выходные)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">Тариф за выход</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 1 : 5].shiftFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 60</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 1 : 5].less60BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 80</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 1 : 5].less80BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 1 : 5].less100BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок более 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 1 : 5].moreOrEqual100BottlesFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заявки - день (выходные)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">Тариф за выход</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 2 : 6].shiftFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 60</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 2 : 6].less60BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 80</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 2 : 6].less80BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 2 : 6].less100BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок более 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 2 : 6].moreOrEqual100BottlesFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заявки - вечер (выходные)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">Тариф за выход</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 3 : 7].shiftFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 60</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 3 : 7].less60BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 80</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 3 : 7].less80BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 3 : 7].less100BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок более 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 3 : 7].moreOrEqual100BottlesFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заявки - быстрая доставка (выходные)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">Тариф за выход</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 8 : 9].shiftFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 60</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 8 : 9].less60BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 80</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 8 : 9].less80BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок до 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 8 : 9].less100BottlesFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">Банок более 100</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffDeliveryRequestOptionsArray[waterCompanyId == 1? 8 : 9].moreOrEqual100BottlesFee">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="card card-primary card-outline">
                      <div class="card-header">
                        <h2>
                          Заказы
                        </h2>
                      </div>

                      <table class="table table-sm table-bordered">
                        <thead>
                        <tr>
                          <th colspan="2">заказы - утро (выходные)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">1 точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].onePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].oneBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 морс</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].oneFruitDrinkFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут 6л</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].one6LiterBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].oneCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные точки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].undeliveredPointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные бутылки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].undeliveredBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленный кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].undeliveredCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].pickUpBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].pickUpBottlePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">платная доставка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].paidDeliveryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">доплата за центр</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 0 : 4].centerBonusFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заказы - утро/день (выходные)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">1 точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].onePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].oneBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 морс</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].oneFruitDrinkFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут 6л</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].one6LiterBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].oneCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные точки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].undeliveredPointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные бутылки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].undeliveredBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленный кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].undeliveredCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].pickUpBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].pickUpBottlePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">платная доставка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].paidDeliveryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">доплата за центр</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 1 : 5].centerBonusFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заказы - день (выходные)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">1 точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].onePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].oneBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 морс</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].oneFruitDrinkFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут 6л</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].one6LiterBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].oneCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные точки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].undeliveredPointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные бутылки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].undeliveredBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленный кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].undeliveredCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].pickUpBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].pickUpBottlePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">платная доставка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].paidDeliveryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">доплата за центр</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 2 : 6].centerBonusFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заказы - вечер (выходные)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">1 точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].onePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].oneBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 морс</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].oneFruitDrinkFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут 6л</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].one6LiterBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].oneCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные точки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].undeliveredPointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные бутылки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].undeliveredBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленный кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].undeliveredCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].pickUpBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].pickUpBottlePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">платная доставка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].paidDeliveryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">доплата за центр</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 3 : 7].centerBonusFee">
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <tr>
                          <th colspan="2">заказы - быстрая доставка (выходные)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="text-right">1 точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].onePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].oneBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 морс</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].oneFruitDrinkFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 бут 6л</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].one6LiterBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">1 кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].oneCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные точки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].undeliveredPointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленные бутылки</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].undeliveredBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">недоставленный кулер</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].undeliveredCoolerFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].pickUpBottleFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">забор тары точка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].pickUpBottlePointFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">платная доставка</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].paidDeliveryFee">
                          </td>
                        </tr>
                        <tr>
                          <td class="text-right">доплата за центр</td>
                          <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                                     v-model="weekendTariff.forwarderTariffOrderOptionsArray[waterCompanyId == 1? 8 : 9].centerBonusFee">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary" @click="updateAll(waterCompanyId)">Сохранить</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- content off -->
  </div>
</template>

<style scoped>

</style>