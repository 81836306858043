<script setup>


import {useUserStore} from "@/store";
import {computed, onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from 'vue-toastification';
import fetchDataWithLocalStorage from "@/common/localStorage";
import format from "date-fns/format";
import {parse} from "date-fns";
import axios from "axios";
import FileSaver from "file-saver";

const toast = useToast();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);

const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function (error) {
    showErrors(error.response)
  });
}

const waterCompanyId = ref(1);
const showAddStatementForm = ref(false);
const statements = ref([])
const warehouseId = ref(null);
const warehouses = ref([]);
const date = ref(format(new Date(), 'yyyy-MM-dd'));

const generalStatementDate = ref(null)

const statuses = ref([
  {
    id: 1,
    name: 'Активна'
  },
  {
    id: 2,
    name: 'Завершена'
  },
  {
    id: 3,
    name: 'Сдана'
  }
]);

const formatDate = (dateString) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  return format(date, 'dd.MM.yyyy');
};

const getWarehouses = async (waterCompanyId) => {
  warehouseId.value = null
  warehouses.value = await fetchDataWithLocalStorage('warehouses.wcId:' + waterCompanyId, 1000 * 60 * 10, process.env.VUE_APP_GATE_URL + '/els/api/v1/warehouses?waterCompanyId=' + waterCompanyId)
}

const getAllStatements = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statements').then(function (response) {
    statements.value = response.data.data
  }).catch(function (error) {
    showErrors(error)
  });
}

const downloadFile = async (id) => {
  await axios.get(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement/' + id + '/excel',
      {responseType: 'blob', headers: {'authorization': 'Bearer ' + localStorage.access_token},})
      .then(response => {
        FileSaver.saveAs(response.data, 'Ведомость выручки.xlsx');
      }).catch(console.error)

}
const createStatement = () => {
  api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement',
      {
        waterCompanyId: waterCompanyId.value,
        warehouseId: warehouseId.value,
        date: date.value
      })
      .then(function () {
        toast.success("Ведомость создана")
        showAddStatementForm.value = false
        getAllStatements()
      })
      .catch(function (response) {
        showErrors(response)
      });
}

const updateStatementStatus = async (statementId, statusId) => {
  try {
    await api.patch(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement/status',
        {
          statementId: statementId,
          statusId: statusId,
        })
    toast.success('Статус обновлён')
  } catch (error) {
    getAllStatements()
    showErrors(error)
  }
}

const GetGeneralStatement = async () => {
  try {
    const {data} = await api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement/general/excel',
        {
          date: generalStatementDate.value,
          waterCompanyId: waterCompanyId.value
        }, {responseType: 'blob'})
    //todo сделать обработку blob
    FileSaver.saveAs(data, 'Генеральная ведомость.xlsx')
  } catch (error) {
    toast.error("Не удалось скачать файл")
  }
}


onBeforeMount(() => {
  checkAuth();
  getWarehouses(waterCompanyId.value);
  getAllStatements()
});
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Ведомости</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}">Заказы</router-link>
              </li>
              <li class="breadcrumb-item active">Доставленные</li>
            </ol>
          </div>

        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="card col-md-4">
            <div class="card-title">
              <div class="d-flex col-12">
                <div class="form-group col-md-8"><label for="wcid" class="col-form-label">Компания</label>
                  <select id="wcid" name="wcid" class="form-control" autocomplete="off" v-model="waterCompanyId"
                          @change="getAllStatements(); getWarehouses(waterCompanyId)">
                    <option :value=1 selected="">Хваловские Воды (С-Пб)</option>
                    <option :value=2>Хваловские Воды (Мск)</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <button class="btn btn-primary waves-effect waves-light"
                        @click="showAddStatementForm = !showAddStatementForm">
                  Добавить
                  ведомость
                </button>
              </div>
            </div>
          </div>
          <div class="card col-md-4">
            <div class="card-title">
              <div class="form-group">
                <label class="col-form-label">Дата</label>
              </div>
              <div class="form-group">
                <input v-model="generalStatementDate" type="date">
              </div>
              <div class="form-group">
                <button class="btn btn-primary waves-effect waves-light"
                        @click="GetGeneralStatement">
                  Скачать генеральную ведомость
                </button>
              </div>
            </div>
          </div>

        </div>

        <div class="card card-primary card-outline" v-if="showAddStatementForm">
          <div class="card-body">
            <div class="card-header"><h2> Добавление ведомости </h2></div>
            <table class="table table-sm">
              <tbody>
              <tr>
                <td class="text-right" style="width: 20px">Склад</td>
                <td><select name="status_id" id="status_id" class="form-control" v-model="warehouseId">
                  <option v-for="w in warehouses" :key="w.id" :value="w.id">
                    {{ w.name }}
                  </option>
                </select>
                </td>
              </tr>
              <tr>
                <td class="text-right">Дата</td>
                <td><input type="date" class="form-control" v-model="date"></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <button class="btn btn-primary" @click="createStatement()">Сохранить</button>
            <button class="btn btn-default float-right" @click="showAddStatementForm = false; newTariffName = ''">
              Отменить
            </button>
          </div>
        </div>
      </div>
      <div class="card  card-outline " v-if="!showAddStatementForm">
        <div class="card-body">
          <table class="table table-striped table-sm table-bordered">
            <thead>
            <tr>
              <th style="text-align: center;">#</th>
              <th style="text-align: center;">Дата</th>
              <th style="text-align: center;">Склад</th>
              <th style="text-align: center;">Статус</th>
              <th style="text-align: center;">Cкачать</th>
              <th style="text-align: center;">Редактировать</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="statement in statements" :key="statement.id">
              <td style="text-align: center;">
                {{ statement.id }}
              </td>
              <td style="text-align: center;">
                {{ formatDate(statement.date) }}
              </td>
              <td style="text-align: center;">
                {{ statement.warehouse.name }}
              </td>
              <td style="text-align: center;">
                {{ statement.status.name }}
                <select class="form-control" v-model="statement.status.id"
                        @change="updateStatementStatus(statement.id, statement.status.id)">
                  <option v-for="status in statuses" :key="status.id" :value="status.id">
                    {{ status.name }}
                  </option>
                </select>
              </td>
              <td style="text-align: center;">
                <button @click="downloadFile(statement.id)" class="btn-success">
                  Скачать файл
                </button>
              </td>
              <td style="text-align: center;">
                <router-link :to="{ name: 'StatementDetailPage', params: { id: statement.id }}">Редактировать
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>

  <!-- content off -->
</template>

<style scoped>

</style>