<script setup>
import {defineProps, defineEmits, ref} from 'vue';
import format from "date-fns/format";

const props = defineProps({
  data: {
    type: Array,
    required: true
  }
});
const emits = defineEmits(['emit-id']);
const isActive = ref(0);

const emitId = (id) => {
  emits('emit-id', id)
  isActive.value = id;
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">
        <i class="fas fa-car mr-1"></i>Заявки ({{ props.data.length }})
      </h3>
    </div>
    <div class="card-body">
      <table class="table table-striped table-sm table-hover">
        <thead>
        <tr>
          <th>#</th>
          <th>Дата</th>
          <th>Сотрудники</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in props.data" :key="index">
          <td >{{ index + 1 }}.</td>
          <td style="width: 25%">{{ format(new Date(item.date), 'dd.MM.yyyy') }}
          <br>
            <span v-for="(interval, index) in item.intervals" :key="interval.id" style="display: block">
              {{ interval.name }}<span v-if="index !== item.intervals.length - 1">,</span>
            </span>
          </td>
          <td>
            <b>{{ item.staffList.driver ? item.staffList.driver.fullName : 'Нет информации' }}</b>
            <div v-for="staff in item.staffList.forwarders" :key="staff.id">
              {{ staff.lastName }} {{ staff.firstName }}
              <br>
            </div>
          </td>
          <td class="text-right">
            <button type="submit" class="btn" :class="{'btn-default': item.id === isActive, 'btn-primary': item.id !== isActive}" @click="() => emitId(item.id)"><i class="fas fa-arrow-right"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>