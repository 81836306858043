import {defineStore} from "pinia";
import api from "@/common/api";

export const useParamsStore = defineStore("paramsStore", {
    state: () => {
        return {
            cities: []
        };
    },
    getters: {
        getUserData: (state) => {
            return state.user;
        },

        getUserRole: (state) => {
            return state.user.role;
        },
        getUserPermissions: (state) => {
            return state.user.permissions;
        }
    },
    actions: {
        setAuthentication(val) {
            this.user.isAuthenticated = val
        },
        setUserRole(val) {
            this.user.role = val
        },
        setPermissions(val) {
            this.user.permissions = val
        },
        async fetchCities () {
            try {
                const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/filter-options/water-companies')
                this.cities = data.data
              } catch (error) {
                console.error(error)
              }
        }
    },
    persist: true,

});