<script setup>
import {defineProps} from "vue";
import format from "date-fns/format";
import {useUserStore} from "@/store";
import {showErrors} from "@/common/showErrors";
import api from "@/common/api";
import {useToast} from "vue-toastification";
const toast = useToast();

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  date: {
    type: String,
    required: true
  }
});
const store = useUserStore();
const userRole = store.user.role;
const userPermissions = store.user.permissions;

const sheetStatuses = [
  {
    name: 'В работе',
    id: 1,
  },
  {
    name: 'Выполнен, не сдан',
    id: 2,
  },
  {
    name: 'Выручка сдана',
    id: 3,
  },
]

const updateSheetStatus = async (sheetId, statusId) => {
  try{
    await api.patch(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/route-sheets/status', {
      deliveryRequestId: sheetId,
      routeSheetStatusId: statusId,
    });
    const updatedSheet = props.item.sheets.find(sheet => sheet.deliveryRequestId === sheetId);
    if (updatedSheet) {
      const newStatus = sheetStatuses.find(status => status.id === statusId);
      if (newStatus) {
        updatedSheet.status = {
          id: newStatus.id,
          description: newStatus.name
        };
      }
    }
    toast.success('Статус обновлён')

  } catch (error) {
    showErrors(error)
  }
}

</script>

<template>
  <div class="card">
    <div class="card-body" style="background: #a4dce7">
      <div class="row">
        <div class="col-2 text-sm">
          <b>{{ format(new Date(props.date), 'dd.MM.yyyy') }}</b>
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-sm">
          <b>Общее кол-во бутылей: </b> {{ item.totalBottles }}
        </div>
        <div class="col-3 text-sm">
          <b>Общее кол-во точек: </b> {{ item.totalOrders }}
        </div>
        <div class="col-3 text-sm">
          <b>Всего рублей к сдаче в кассу: </b> {{ item.amountCashReturn }}&nbsp;&#8381;
        </div>
        <div class="col-3 text-sm">
          <b>Всего сданно в офисе: </b> {{ item.amountReturned }}&nbsp;&#8381;
        </div>
        <div class="col-3 text-sm">
          <b>Недостача: </b> {{ item.shortage }}&nbsp;&#8381;
        </div>
        <div class="col-3 text-sm">
          <b>Всего начислено за смену: </b> {{ item.preliminaryAmount }}&nbsp;&#8381;
        </div>
      </div>
    </div>


    <div class="card-footer">
      <table class="table table-striped">
        <thead>
        <tr>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(sheet, index) in item.sheets" :key="index">
          <td>
            <div class="col-2 text-sm">
              <b v-for="(interval, index) in sheet.intervals" :key="index"> {{ interval.name }} &nbsp;</b>
            </div>
            <div class="row">
              <div class="col-3 text-sm">
                <b>Общее кол-во бутылей: </b> {{ sheet.totalBottles }}
              </div>
              <div class="col-3 text-sm">
                <b>Общее кол-во точек: </b> {{ sheet.totalOrders }}
              </div>
              <div class="col-3 text-sm">
                    <span v-if="((userRole === 'admin') || userPermissions?.includes('create_transactions'))">
                      <b>Установить новый статус</b>
                      <select class="form-control" v-model="sheet.status.id" @change="updateSheetStatus(sheet.deliveryRequestId, sheet.status.id)">
                        <option v-for="status in sheetStatuses"
                                :key="status.id"
                                :value="status.id"
                        >
                          {{ status.name }}
                        </option>
                      </select>
                      <p>Текущий статус: {{ sheet.status?.description || 'Не выбран' }}</p>
                    </span>
                <span v-else>
                  <b>Статус маршрутного листа: </b> {{ sheet.status?.description ?? 'Не доступно' }}
                </span>
              </div>
              <div class="col-3 text-sm">
                <b>Всего рублей к сдаче в кассу: </b> {{ sheet.amountCashReturn }}&nbsp;&#8381;
              </div>
              <div class="col-3 text-sm">
                <b>Всего сданно в офисе: </b> {{ sheet.amountReturned }}&nbsp;&#8381;
              </div>
              <div class="col-3 text-sm">
                <b>Недостача: </b> {{ sheet.shortage }}&nbsp;&#8381;
              </div>
              <div class="col-3 text-sm">
                <b>Всего начисленно за смену: </b> {{ sheet.preliminaryAmount }}&nbsp;&#8381;
              </div>
            </div>
          </td>
        </tr>


        </tbody>
      </table>


    </div>

  </div>
</template>