<script setup>
import {useUserStore} from "@/store";
import {computed, onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from 'vue-toastification';
import SalaryMenu from "@/components/Salary/SalaryMenu.vue";
const toast = useToast();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const time = ref([]);
const waterCompanyId = ref(1);

const getTime = async (waterCompanyId) => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/notify/drivers?waterCompanyId=' + waterCompanyId).then(function (response) {
    time.value = response.data.data.time
  }).catch(function (error) {
    showErrors(error)
    return []
  })
}

const changeTime = async () => {
  await api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/notify/drivers', {
    'waterCompanyId': waterCompanyId.value,
    'time': time.value
  }).then(function () {
    getTime(waterCompanyId.value)
    toast.success("Время обновлено")
  }).catch(function (response) {
    console.log(response)
    showErrors(response)
  })
}



onBeforeMount(() => {
  getTime(waterCompanyId.value);
});

</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Расчёт ЗП за период</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Настройка времени уведомлений о сдаче кассы</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <SalaryMenu></SalaryMenu>
      </div>
    </section>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="card">
              <div class="card-title">
                <div class="form-group col-md-12"><label for="wcid" class="col-form-label">Компания</label>
                  <select id="wcid" name="wcid" class="form-control" autocomplete="off" v-model="waterCompanyId"
                  @change="getTime(waterCompanyId)">
                    <option :value=1 selected="">Хваловские Воды (С-Пб)</option>
                    <option :value=2>Хваловские Воды (Мск)</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Выбрать время</h3>
              </div>
              <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-md-12 text-center">
                      <input type="time" class="form-control" v-model="time">
                    </div>
                    <div class="form-group col-md-12" style="text-align: center;">
                      <button class="btn btn-success btn-sm" @click="changeTime()">Изменить</button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>
</template>

<style scoped>

</style>