import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.css'
import 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.js'
import 'admin-lte/dist/js/adminlte.js'
import 'admin-lte/dist/css/adminlte.css'
import 'admin-lte/plugins/fontawesome-free/css/all.css'
import 'vue-toastification/dist/index.css';



import { createApp } from 'vue'
import App from './App.vue'
import router  from "@/router";
import Toast from "vue-toastification";
import axios from "axios";
import VueAxios from "vue-axios";
import {createPinia} from "pinia";
import { UidPlugin } from '@shimyshack/uid'

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)


const app = createApp(App)
    .use(router)
    .use(pinia)
    .use(Toast)
    .use(Toast)
    .use(VueAxios, axios)
    .use(UidPlugin)

// app.config.globalProperties.$jquery = $;
//
// app.mixin({
//     beforeCreate() {
//         if (typeof window.$ === 'undefined') {
//             // Load jQuery here
//             window.$ = window.jQuery = $;
//         }
//     }
// });

app.mount('#app')