<script setup>
import {onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {} from "@/common/showErrors";
import {useRoute} from "vue-router";
import {showErrors} from "@/common/showErrors";
import StaffForManagerMenu from "@/components/Staff/StaffForManagerMenu.vue";
import ru from "date-fns/locale/ru";
import Datapicker from "vue3-datepicker";
import format from "date-fns/format";
import {useToast} from "vue-toastification";
import router from "@/router";

const toast = useToast();

const route = useRoute();
const staff = ref(null);
const currentBalance = ref(null);

const dateFrom = ref(null);
const dateTo = ref(null);
const getStaff = async (staffId) => {
  try {
    const {data} = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/staff/' + staffId)
    staff.value = data.data
  } catch (e) {
    showErrors(e)
  }
}

const getCurrentBalance = async (staffId) => {
  try {
    const {data} = await api.get(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/driver-balance/' + staffId)
    currentBalance.value = data.data
  } catch (e) {
    showErrors(e)
  }
}

const debt = ref(null)
const getDebtsForInterval = async () => {
  try {
    const {data} = await api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/staff-debt/period'
        , {
          dateFrom: format(dateFrom.value, 'yyyy-MM-dd'),
          dateTo: format(dateTo.value, 'yyyy-MM-dd'),
          staffId: staff.value.id
        })
      debt.value = data.data

  } catch (e) {
    showErrors(e)
  }
}



const updateDebt= async (debt) => {
  try {
    await api.put(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/staff-debt/', debt)
    toast.success('Долг обновлён')
  } catch (e) {
    showErrors(e)
  } finally {
      getDebtsForInterval()

  }
}


onBeforeMount(() => {
  getStaff(route.params.staffId)
  getCurrentBalance(route.params.staffId)
});
</script>

<template>
  <div class="content-wrapper">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-9">
            <H1>Внебалансовые данные по {{ staff?.firstName }} {{ staff?.lastName }} {{ staff?.patronymic }}</H1>
          </div>
          <div class="col-sm-3">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <StaffForManagerMenu></StaffForManagerMenu>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-2">
            <div class="card">
              <button class="btn btn-success" @click="router.push({name: 'CreateDebtPage'})">Добавить
                долг
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-3">
            <div class="card">
              <div class="card-header ui-sortable-handle" style="cursor: move;">
                <h3 class="card-title">
                  <i class="fas fa-filter mr-1"></i>Показать за диапазон дат
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="wcId" class="col-form-label">Дата c</label>
                    <Datapicker
                        class="form-control"
                        v-model="dateFrom"
                        :locale="ru"
                        input-format='dd.MM.yyyy'
                        typeable
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="wcId" class="col-form-label">Дата по</label>
                    <Datapicker
                        class="form-control"
                        v-model="dateTo"
                        :locale="ru"
                        :lower-limit="dateFrom"
                        input-format='dd.MM.yyyy'
                        typeable
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <button class="btn btn-primary waves-effect waves-light" @click="getDebtsForInterval"
                            :disabled="!(dateFrom && dateTo)">
                      Найти
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" v-if="debt">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <h3>Общий долг за интервал: {{ debt?.totalDebtAmount?.value }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" v-if="debt">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <h1>Долги</h1>
                    <div class="form-row">
                      <table class="table table-striped table-sm">
                        <thead>
                        <tr>
                          <th>Сумма</th>
                          <th>Причина</th>
                          <th>Провёл</th>
                          <th>Дата</th>
                          <th>Изменить</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in debt?.staffDebts" :key="index">
                          <td>
                           <input class="form-data" type="number" v-model="item.debtAmount">
                          </td>
                          <td>
                            <input class="form-data" type="text" v-model="item.reason">
                          </td>
                          <td>
                            {{item?.createdBy?.name}}
                          </td>
                          <td>
                            {{item.createdAt}}
                          </td>
                          <td><button class="btn btn-info" @click="updateDebt(item)">Изменить</button></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </section>
  </div>
  <!-- content off -->
</template>

<style scoped>

</style>