import {defineStore} from "pinia";

export const useUserStore = defineStore("userStore", {
    state: () => {
        return {
            user: {
                isAuthenticated: false,
                role: null,
                permissions: null,
                departmentId: null,
            }
        };
    },
    getters: {
        getUserData: (state) => {
            return state.user;
        },

        getUserRole: (state) => {
            return state.user.role;
        },
        getUserPermissions: (state) => {
            return state.user.permissions;
        },
        getDepartmentId: (state) => {
            return state.user.departmentId;
        }
    },
    actions: {
        setAuthentication(val) {
            this.user.isAuthenticated = val
        },
        setUserRole(val) {
            this.user.role = val
        },
        setPermissions(val) {
            this.user.permissions = val
        },
        setDepartmentId(val) {
            this.user.departmentId = val
        }
    },
    persist: true,

});