<script setup>
import {onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {} from "@/common/showErrors";
import {useRoute} from "vue-router";
import {showErrors} from "@/common/showErrors";
import StaffForManagerMenu from "@/components/Staff/StaffForManagerMenu.vue";
import ru from "date-fns/locale/ru";
import Datapicker from "vue3-datepicker";
import format from "date-fns/format";
import {useToast} from "vue-toastification";
import {inputMethods, moneyTypes, operationTypes} from "../Transaction/types";
import router from "@/router";

const toast = useToast();

const route = useRoute();
const staff = ref(null);
const currentBalance = ref(null);

const dateFrom = ref(null);
const dateTo = ref(null);
const dateCurrent = ref(null);
const getStaff = async (staffId) => {
  try {
    const {data} = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/staff/' + staffId)
    staff.value = data.data
  } catch (e) {
    showErrors(e)
  }
}

const getCurrentBalance = async (staffId) => {
  try {
    const {data} = await api.get(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/driver-balance/' + staffId)
    currentBalance.value = data.data
  } catch (e) {
    showErrors(e)
  }
}

const balance = ref(null)
const getBalanceForInterval = async () => {
  try {
    const {data} = await api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/balance-transactions/period'
        , {
          dateFrom: format(dateFrom.value, 'yyyy-MM-dd') + ' 00:00:00',
          dateTo: format(dateTo.value, 'yyyy-MM-dd') + ' 23:59:59',
          staffId: staff.value.id
        })
    if (data.data[0]) {
      balance.value = data.data[0]
    } else {
      balance.value = null
      toast.info('Нету данных')
    }

  } catch (e) {
    showErrors(e)
  }
}

const getBalanceForCurrentDate = async () => {
  try {
    const {data} = await api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/balance-transactions/of-date/driver'
        , {
          date: format(dateCurrent.value, 'yyyy-MM-dd') + ' 23:59:59',
          staffId: staff.value.id
        })
    if (data.data[0]) {
      balance.value = data.data[0]
    } else {
      balance.value = null
      toast.info('Нету данных')
    }

  } catch (e) {
    showErrors(e)
  }
}

const updateTransaction = async (transaction) => {
  try {
    await api.put(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/balance-transactions/', transaction)
    toast.success('Транзакция обновлена')
    getCurrentBalance(route.params.staffId)

  } catch (e) {
    showErrors(e)
  } finally {
    if (dateFrom.value && dateTo.value) {
      getBalanceForInterval()
    } else {
      getBalanceForCurrentDate()
    }
  }
}


onBeforeMount(() => {
  getStaff(route.params.staffId)
  getCurrentBalance(route.params.staffId)
});
</script>

<template>
  <div class="content-wrapper">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-9">
            <H1>Баланс водителя {{ staff?.firstName }} {{ staff?.lastName }} {{ staff?.patronymic }}</H1>
          </div>
          <div class="col-sm-3">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <StaffForManagerMenu></StaffForManagerMenu>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10">
            <div class="card">
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <h1>Текущий баланс {{ currentBalance?.balanceAmount }}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="card">
              <button class="btn btn-success" @click="router.push({name: 'CreateTransactionPage'})">Добавить
                транзакцию
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-3">
            <div class="card">
              <div class="card-header ui-sortable-handle" style="cursor: move;">
                <h3 class="card-title">
                  <i class="fas fa-filter mr-1"></i>Показать за диапазон дат
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="wcId" class="col-form-label">Дата c</label>
                    <Datapicker
                        class="form-control"
                        v-model="dateFrom"
                        :locale="ru"
                        input-format='dd.MM.yyyy'
                        typeable
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="wcId" class="col-form-label">Дата по</label>
                    <Datapicker
                        class="form-control"
                        v-model="dateTo"
                        :locale="ru"
                        :lower-limit="dateFrom"
                        input-format='dd.MM.yyyy'
                        typeable
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <button class="btn btn-primary waves-effect waves-light" @click="getBalanceForInterval"
                            :disabled="!(dateFrom && dateTo)">
                      Найти
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="card">
              <div class="card-header ui-sortable-handle" style="cursor: move;">
                <h3 class="card-title">
                  <i class="fas fa-filter mr-1"></i>Показать на конкретную дату
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="wcId" class="col-form-label">Дата</label>
                    <Datapicker
                        class="form-control"
                        v-model="dateCurrent"
                        :locale="ru"
                        input-format='dd.MM.yyyy'
                        typeable
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <button class="btn btn-primary waves-effect waves-light" @click="(getBalanceForCurrentDate())"
                            :disabled="!dateCurrent">
                      Найти
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" v-if="balance">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <h3>Дебит {{ balance?.debitAmount }} | Бумажные {{ balance?.cashAmount }} | Мелочь
                      {{ balance?.metalAmount }} | Баланс {{ balance?.balanceAmount }} | Кредит
                      {{ balance?.creditAmount }} | Балансовый долг {{ balance?.debtSum }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" v-if="balance">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <h1>Транзакции</h1>
                    <div class="form-row">
                      <table class="table table-striped table-sm">
                        <thead>
                        <tr>
                          <th>Тип операции</th>
                          <th>Сумма</th>
                          <th>Причина</th>
                          <th>Тип денег</th>
                          <th>Способ внесения</th>
                          <th>Провёл</th>
                          <th>Дата</th>
                          <th>Изменить</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in balance?.balanceTransactionArray" :key="index">
                          <td>
                            <select class="form-control" v-model="item.operationType">
                              <option v-for="operationType in operationTypes"
                                      :value="operationType.value"
                                      :key="operationType.value"
                              >
                                {{ operationType.name }}
                              </option>
                            </select></td>
                          <td><input v-model="item.amount" class="form-control" type="number"></td>
                          <td><input v-model="item.reason" class="form-control" type="text"></td>
                          <td><select class="form-control" v-model="item.moneyType">
                            <option v-for="moneyType in moneyTypes"
                                    :value="moneyType.value"
                                    :key="moneyType.value"
                            >
                              {{ moneyType.name }}
                            </option>
                          </select>
                          </td>
                          <td><select class="form-control" v-model="item.inputMethod">
                            <option v-for="inputMethod in inputMethods"
                                    :value="inputMethod.value"
                                    :key="inputMethod.value"
                            >
                              {{ inputMethod.name }}
                            </option>
                          </select>
                          </td>
                          <td>{{ item?.createdBy?.name }}</td>
                          <td><input v-model="item.transactionDate" class="form-control" type="text"></td>
                          <td>
                            <btn class="btn btn-info" @click="updateTransaction(item)">Изменить</btn>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </section>
  </div>
  <!-- content off -->
</template>

<style scoped>

</style>