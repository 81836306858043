<template>
  <div class="card">
    <div class="card-body">
      <router-link :to="{ name: 'AutoPaymentPage' }" class="">
        <i :class="{ 'nav-icon': true, 'fa': true, 'fa-credit-card': true }"></i> Автосписание
      </router-link>
      |
      <router-link :to="{ name: 'AutoPaymentSettingPage' }" class="">
        <i :class="{ 'nav-icon': true, 'fa': true, 'fa-keyboard': true }"></i> Настройки
      </router-link>
      |
      <router-link :to="{ name: 'AutoPaymentLogPage' }" class="">
        <i :class="{ 'nav-icon': true, 'fa': true, 'fa-paste': true }"></i> Лог
      </router-link>
    </div>
  </div>
</template>