<script setup>
import {onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useRoute} from "vue-router";
import DepartmentPageMenu from "@/components/Departments/DepartmentMenu.vue";
import {useToast} from "vue-toastification";

const post = ref({
  id: null,
  name: null,
  departmentId: null,
  permissions: []
})


const usersOnPost = ref([]);
const departmentUsers = ref([]);
const targetUserIdForAdd = ref(null);
const targetUserIdForDelete = ref(null);
const targetPermissionIdForAdd = ref(null);
const targetPermissionIdForDelete = ref(null);
const toast = useToast();
const departmentPermissions = ref([]);

const getDepartmentPermissions = async (id) => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + id + '/permissions').then(function (response) {
    departmentPermissions.value = response.data.data
  }).catch(function (error) {
    showErrors(error)
  })
}


const getDepartmentUsers = async (id) => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + id + '/users').then(function (response) {
    const allUsers = response.data.data;
    departmentUsers.value = allUsers.filter(user =>
        user.perm_service_post_id === null
    );
  }).catch(function (error) {
    showErrors(error)
  })
}

const getPost = (id) => {
  api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/post/' + id).then(function (response) {
    post.value.id = id
    post.value.name = response.data.data.name
    post.value.permissions = response.data.data.permissions
    usersOnPost.value = response.data.data.users

  }).catch(function (error) {
    showErrors(error.response)
  })
}

const updatePost = async (id) => {
  api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/post/' + id, post.value).then(function () {
    toast.success("Успешно обновлено")
  }).catch(function (error) {
    showErrors(error.response)
  })
}

const setUserPost = (userId, postId) => {
  api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/post/' + postId + '/user/' + userId).then(function () {
    toast.success('Успешно обновлено')
    getPost(route.params.postId);
    getDepartmentUsers(route.params.id);
  }).catch(function (error) {
    showErrors(error.response)
  })
}

const deleteUserFromPost = (userId) => {
  api.delete(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/user/' + userId + '/post').then(function () {
    toast.success('Успешно обновлено')
    getPost(route.params.postId);
    getDepartmentUsers(route.params.id);
  }).catch(function (error) {
    showErrors(error.response)
  })
}

const addPermission = (id) => {
  let perm = departmentPermissions.value.find(perm => perm.id === id);
  if (perm && !post.value.permissions.some(p => p.id === perm.id)) {
    post.value.permissions.push({id: perm.id, name: perm.name, description: perm.description});

    api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/post/' + post.value.id + '/permissions',
        { permissions: post.value.permissions.map(p => p.id) })
        .then(function () {
          toast.success('Успешно');
        }).catch(function (error) {
      post.value.permissions = post.value.permissions.filter(p => p.id !== perm.id);
      showErrors(error.response)
    });

  } else if (perm) {
    toast.info('Разрешение уже есть');
  } else {
    toast.error('Разрешение не найдено');
  }
}

const deletePermission = (id) => {
  post.value.permissions = post.value.permissions.filter(p => p.id !== id);
  api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/post/' + post.value.id + '/permissions',
      { permissions: post.value.permissions.map(p => p.id) })
      .then(function () {
        toast.success('Успешно');
      }).catch(function (error) {
    showErrors(error.response)
  });
}

const route = useRoute();
onBeforeMount(() => {
  getPost(route.params.postId);
  getDepartmentUsers(route.params.id);
  getDepartmentPermissions(route.params.id);

});
</script>
<template>
  <div class="content-wrapper"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Редактирование должности {{ post.name }}</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Отделы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <DepartmentPageMenu></DepartmentPageMenu>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Название</label>
                      <input v-model="post.name" type="text" class="form-control">
                    </div>
                  </form>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-info" @click="updatePost(route.params.postId)">Обновить
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Список сотрудников без должности</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" size="25" v-model="targetUserIdForAdd">
                        <option v-for="user in departmentUsers" :key="user.id" :value="user.id">{{ user.name }}</option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info"
                          @click="setUserPost(targetUserIdForAdd,route.params.postId)">Назначить на должность
                  </button>
                </div>
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info" @click="deleteUserFromPost(targetUserIdForDelete)">
                    Снять с должности
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Сотрудники с этой должностью</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" v-model="targetUserIdForDelete" size="25">
                        <option v-for="user in usersOnPost" :key="user.id" :value="user.id">
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Список доступных прав в отделе</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" size="25" v-model="targetPermissionIdForAdd">
                        <option v-for="permission in departmentPermissions" :key="permission.id" :value="permission.id">
                          {{ permission.name }} | {{ permission.description }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info" @click="addPermission(targetPermissionIdForAdd)">Добавить
                  </button>
                </div>
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info" @click="deletePermission(targetPermissionIdForDelete)">
                    Убрать
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Выбранные для должности {{ post.name }}</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" v-model="targetPermissionIdForDelete" size="25">
                        <option v-for="permission in post.permissions" :key="permission.id" :value="permission.id">
                          {{ permission.name }} | {{ permission.description }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>
