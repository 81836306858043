export function getNameByValue(value, array) {
    const item = array.find(item => item.value === value);
    return item ? item.name : undefined;
}

export const operationTypes = [
    {
        name: "Приход",
        value: "debit",
    },
    {
        name: "Списание",
        value: "credit",
    }
]

export const moneyTypes = [
    {
        name: "Бумажные",
        value: "cash",
    },
    {
        name: "Мелочь",
        value: "metal",
    }
]

export const inputMethods = [
    {
        name: "Терминал",
        value: "terminal",
    },
    {
        name: "Сейф",
        value: "safe",
    },
    {
        name: "Офис",
        value: "office",
    }
]