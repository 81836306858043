<template>
  <div class="card">
    <div class="card-body">
     <span v-if="userRole.includes('admin')"> <router-link :to="{ name: 'DepartmentMainSettingsPage' }" class="">
        Основные
      </router-link>
      |
     </span>
      <router-link :to="{ name: 'DepartmentUsersPage' }" class="">
       Пользователи
      </router-link>
      |
      <router-link :to="{ name: 'DepartmentPostsPage' }" class="">
        Должности
      </router-link>
    </div>
  </div>
</template>
<script setup>
import {useUserStore} from "@/store";
import {computed} from "vue";

const store = useUserStore();
const userRole = computed(() => store.user.role);
</script>