<script setup>
import {computed, onBeforeMount, ref} from "vue";
import {useUserStore} from "@/store";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";
import AutoPaymentMenu from "@/components/AutoPayment/AutoPaymentMenu.vue";

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const toast = useToast();

const wcId = ref(1);
const searchActive = ref(false);
const settings = ref({});



const update = async () => {
  searchActive.value = !searchActive.value;
  settings.value.waterCompanyId = wcId.value;
  await api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/auto-payment/settings', settings.value).then(function (response) {
    if (response.data) {
      toast.success("Настройка обновлена");
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  });
  searchActive.value = !searchActive.value;
}

const getSettings = async () => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/auto-payment/settings', {
    params: {
      waterCompanyId: wcId.value,
    }
  }).then(function (response) {
    settings.value = response.data.data
    if (!settings.value) {
      toast.info("Настройка не найдена");
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  });
}

onBeforeMount(() => {
  getSettings();
});

</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Настройка</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Сервисы</a></li>
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'AutoPaymentPage' }" class="">Автосписание</router-link>
              </li>
              <li class="breadcrumb-item active">Настройка</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <AutoPaymentMenu></AutoPaymentMenu>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-list mr-2"></i>Настройка
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="wcId" class="col-form-label">Город</label>
                    <select id="wcId" class="form-control" autocomplete="off" v-model="wcId" @change="getSettings">
                      <option :value=1>Санкт-Петербург</option>
                      <option :value=2>Москва</option>
                    </select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="count" class="col-form-label">Стоимость 1 бутыля ₽</label>
                    <input id="count" type="number" min="0" max="999999" class="form-control" placeholder="10"
                           v-model="settings.cost">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="count" class="col-form-label">Количество заказов</label>
                    <input id="count" type="number" min="0" max="999999" class="form-control" placeholder="10"
                           v-model="settings.deliveredOrdersTargetCount">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="count" class="col-form-label">Дней с последнего заказа от</label>
                    <input id="count" type="number" min="0" max="999999" class="form-control" placeholder="10"
                           v-model="settings.lastOrderDaysFrom">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="count" class="col-form-label">Дней с последнего заказа до</label>
                    <input id="count" type="number" min="0" max="999999" class="form-control" placeholder="10"
                           v-model="settings.lastOrderDaysTo">
                  </div>
                  <div class="form-group col-md-12">
                    <label for="count" class="col-form-label">Тип продукции в последнем заказе</label>
                    <div class="form-group">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="settings.water">
                        <label class="form-check-label">Вода</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="settings.pumps">
                        <label class="form-check-label">Помпы</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="settings.any">
                        <label class="form-check-label">Любые</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="count" class="col-form-label">Оплата через ИП</label>
                    <div class="form-group">
                      <div class="form-check" v-for="(paymentSetting, index) in settings.paymentSettings" :key="paymentSetting.id">
                        <input class="form-check-input" type="checkbox" v-model="settings.paymentSettings[index].active"
                               :value="paymentSetting">
                        <label class="form-check-label">{{ paymentSetting.name }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-auto" style="padding-top: 38px;">
                    <button type="submit" class="btn btn-primary waves-effect waves-light" @click="update">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            v-if="searchActive"></span>
                      Сохранить
                    </button>
                  </div>
                </div>
                <div class="form-row">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>
</template>

<style scoped>

</style>