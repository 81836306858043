import staffLinks from './sidebarStaffLinks.js'

export const getSideBarLinks = (userPermissions, userRole, departmentId) => {
    console.log(userPermissions, userRole, departmentId)
    if(userRole === 'staff') {
        return staffLinks
    }
    const result = {
        clients: {
            title: ' Клиенты',
            href: {},
            icon: {'nav-icon': true, 'fas': true, 'fa-users': true},
            childs: [
                {
                    title: ' Поиск клиентов',
                    href: {name: 'CustomerSearch'},
                    icon: {'nav-icon': true, 'fa': true, 'fa-search': true},
                },
                {
                    title: ' Оборудование ',
                    href: {name: 'CustomerEquipment'},
                    icon: {'nav-icon': true, 'fa': true, 'fa-toolbox': true},
                }
            ]
        },
        orders: {
            title: ' Заказы',
            href: {},
            icon: {'nav-icon': true, 'fas': true, 'fa-box': true},
            childs: [
                {
                    title: ' Добавить заказ',
                    href: {name: 'AddOrder'},
                    icon: {'nav-icon': true, 'fa': true, 'fa-handshake': true},
                },
                {
                    title: ' Доставленные ',
                    href: {name: 'DeliveredOrders'},
                    icon: {'nav-icon': true, 'fa': true, 'fa-truck': true},
                }

            ]
        },
        services: {
            title: ' Сервисы',
            href: {},
            icon: {'nav-icon': true, 'fa': true, 'fa-building': true},
            childs: [
                {
                    title: 'Автосписание',
                    href: {name: 'AutoPaymentPage'},
                    icon: {'nav-icon': true, 'fa': true, 'fa-credit-card': true},
                },
                {
                    title: ' Добавить dataMatrix ',
                    href: {name: 'DataMatrixPage'},
                    icon: {'nav-icon': true, 'fa': true, 'fa-clone': true},
                },
                {
                    title: ' Сбербанк логи ',
                    href: {name: 'SberbankLogPage'},
                    icon: {'nav-icon': true, 'fa': true, 'fa-university': true},
                },
                {
                    title: 'Уведомления',
                    href: {name: 'DriversNotifySettingPage'},
                    icon: {'nav-icon': true, 'fa': true, 'fa-envelope': true},
                }
            ]
        },
        warehouse: {
            title: ' Склад',
            href: {name: 'WarehouseMain'},
            icon: {'nav-icon': true, 'fa': true, 'fa-warehouse': true},
            childs: []
        },
        reports: {
            title: ' Отчёты',
            href: {name: 'ReportsMain'},
            icon: {'nav-icon': true, 'fa': true, 'fa-file': true},
            childs: []
        },
        settings: {
            title: ' Настройки',
            href: {name: 'SettingsMain'},
            icon: {'nav-icon': true, 'fa': true, 'fa-wrench': true},
            childs: []
        },
        employees: {
            title: ' Сотрудники',
            href: {},
            icon: {'nav-icon': true, 'fas': true, 'fa-users': true},
            childs: [
                {
                    title: ' Водители/Экспедиторы ',
                    href: {name: 'StaffListPage'},
                    icon: {'nav-icon': true, 'fa': true, 'fa-user': true},
                },
                {
                    title: ' ЗП: Водители/экспедиторы ',
                    href: {name: 'DriverSalaryPage'},
                    icon: {'nav-icon': true, 'fa': true, 'fa-truck': true},
                }
            ]
        },
        userMain: {
            title: ' Мой профиль',
            href: {name: 'UserMain'},
            icon: {'nav-icon': true, 'fa': true, 'fa-user': true},
            childs: []
        }
    }

    if (userPermissions?.includes('admin') || userPermissions?.includes('closeOrder')) {
        result.orders.childs.push({
            title: ' Закрытие заказа ',
            href: {name: 'CloseOrderPage'},
            icon: {'nav-icon': true, 'fa': true, 'fa-clone': true},
        })
    }

    if (userRole === 'admin') {
        result.employees.childs.push({
            'title': 'Отделы',
            href: {name: 'DepartmentsPage'},
            icon: {'nav-icon': true, 'fa': true, 'fa-building': true},
        })
    }

    if (userRole === 'departmentOwner' || userRole === 'admin') {
        result.employees.childs.push({
            title: 'Офис',
            href: {name: 'DepartmentUsersPage', params: {id: departmentId ? departmentId : 0}},
            icon: {'nav-icon': true, 'fa': true, 'fa-building': true},

        })
    }

    if (userRole === 'admin' || userPermissions?.includes('view_statements')) {
        result.orders.childs.push({
            title: 'Ведомости',
            href: {name: 'StatementsPage'},
            icon: {'nav-icon': true, 'fa': true, 'fa-file': true},

        })
    }

    return result
}