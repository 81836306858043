<script setup>
import {onBeforeMount, ref} from "vue";
import '@vuepic/vue-datepicker/dist/main.css';
import router from "@/router";
import UIInput from "@/components/UI/UIInput.vue";
import {useRoute} from "vue-router";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";

const route = useRoute();
const toast = useToast();

class debtModel {
  staffId = route.params.staffId
  debtAmount = null
  reason = null
}

const debt = ref(new debtModel());


const createDebt = async () => {
  try {
    await api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/staff-debt', debt.value)
    toast.success('Успешно')
    debt.value = ref(new debtModel());

  } catch (error) {
    showErrors(error)
  }
}

onBeforeMount(() => {
});
</script>
<template>
  <div class="content-wrapper"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Добавить новый долг</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Транзакции</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <UIInput label="Причина" v-model="debt.reason" class="col-md-12 col-xl-12"></UIInput>
                      <UIInput label="Сумма" v-model="debt.debtAmount" class="col-md-12 col-xl-12" type="number"></UIInput>
                    </div>
                  </form>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-info" @click="createDebt()">Добавить</button>
                    <button type="submit" class="btn btn-default float-right"
                            @click="router.go(-1)">Назад
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>
