import api from "@/common/api";
import {showErrors} from "@/common/showErrors";

const fetchDataWithLocalStorage = async (storageKey, exp, url) => {
    if (localStorage.getItem(storageKey)) {
        const storedData = JSON.parse(localStorage.getItem(storageKey));
        if (new Date().getTime() > storedData.expirationTime) {
            localStorage.removeItem(storageKey);
        }
        return storedData.data;
    }
    return api.get(url).then(function (response) {
        let expirationTime = new Date().getTime() + exp;
        let data = response.data;
        if (data.data) {
            data = data.data
        }
        localStorage.setItem(storageKey, JSON.stringify({data, expirationTime}))
        return data
    }).catch(function (error) {
        showErrors(error.response)
        return []
    })
}

export default fetchDataWithLocalStorage