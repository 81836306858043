<script setup>
import {computed, onBeforeMount, ref} from "vue";
import {useUserStore} from "@/store";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";

const toast = useToast();
const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);

const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function (error) {
    showErrors(error.response)
  });
}

onBeforeMount(() => {
  checkAuth();
});

const orders = ref([]);
const orderProducts = ref({});
const orderFilter = ref({
  orderNumber: '',
  orderId: '',
  oldId: '',
  phone: ''
});
const buttonActive1 = ref(false);
const buttonActive2 = ref({});

const searchOrders = async () => {
  buttonActive1.value = !buttonActive1.value;
  orderProducts.value = {};
  orders.value = [];
  if (!orderFilter.value.orderNumber && !orderFilter.value.orderId && !orderFilter.value.oldId && !orderFilter.value.phone) {
    buttonActive1.value = !buttonActive1.value;
    return;
  }
  let searchSet = {};
  if (orderFilter.value.orderId) {
    searchSet['orderId'] = orderFilter.value.orderId
  }
  if (orderFilter.value.orderNumber) {
    searchSet['orderNumber'] = orderFilter.value.orderNumber
  }
  if (orderFilter.value.oldId) {
    searchSet['oldId'] = orderFilter.value.oldId
  }
  if (orderFilter.value.phone) {
    searchSet['phone'] = orderFilter.value.phone
  }
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/orders/search-undelivered', {
    params: searchSet
  }).then(function (response) {
    orders.value = response.data.data
    if (orders.value.length === 0) {
      toast.info("Заказы не найдены")
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
  buttonActive1.value = !buttonActive1.value;
}

const closeOrder = async (orderId) => {
  buttonActive2.value[orderId] = !buttonActive2.value[orderId];
  orderProducts.value = {};
  await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/order/' + orderId + '/close-by-manager').then(function () {
    toast.success("Заказ закрыт")
    orders.value = [];
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
  buttonActive2.value[orderId] = !buttonActive2.value[orderId];
}


const isFieldDisabled = (field) => {
  const {orderNumber, orderId, oldId, phone} = orderFilter.value;
  switch (field) {
    case 'orderNumber':
      return orderId !== '' || oldId !== '' || phone !== '';
    case 'orderId':
      return orderNumber !== '' || oldId !== '' || phone !== '';
    case 'oldId':
      return orderNumber !== '' || orderId !== '' || phone !== '';
    case 'phone':
      return orderNumber !== '' || orderId !== '' || oldId !== '';
    default:
      return false;
  }
}

</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Закрыть заказ</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Заказы</a></li>
              <li class="breadcrumb-item active">Закрыть заказ</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-search mr-2"></i>Этап 1: Поиск заказа
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-2">
                    <label for="orderNumber" class="col-form-label">Номер заказа</label>
                    <input type="text" class="form-control" placeholder="123456-7890-12" min="0" max="999999"
                           v-model="orderFilter.orderNumber" :disabled="isFieldDisabled('orderNumber')">
                  </div>
                  <div class="form-group col-md-2">
                    <label for="orderId" class="col-form-label">ID заказа</label>
                    <input type="number" class="form-control" placeholder="1234567" min="0" max="999999"
                           v-model="orderFilter.orderId" :disabled="isFieldDisabled('orderId')">
                  </div>
                  <div class="form-group col-md-2">
                    <label for="orderOldId" class="col-form-label">OLD ID заказа</label>
                    <input type="number" class="form-control" placeholder="12345" min="0" max="999999"
                           v-model="orderFilter.oldId" :disabled="isFieldDisabled('oldId')">
                  </div>
                  <div class="form-group col-md-2">
                    <label for="зрщту" class="col-form-label">Номер клиента</label>
                    <input type="text" class="form-control" placeholder="7999999999" v-model="orderFilter.phone"
                           :disabled="isFieldDisabled('phone')">
                  </div>
                  <div class="form-group col-md-auto" style="padding-top: 38px;">
                    <button type="submit" class="btn btn-primary waves-effect waves-light" @click="searchOrders">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            v-if="buttonActive1"></span>
                      Найти
                    </button>
                  </div>
                </div>
                <div class="form-row">
                </div>
              </div>
            </div>

            <div class="card" v-if="orders.length > 0">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-list mr-2"></i>Этап 2: выбор заказа
                </h3>
              </div>
              <div class="card-body">
                <table class="table table-striped table-sm">
                  <tbody>
                  <tr v-for="item in orders" :key="item.id">
                    <td>
                      №{{ item.orderNumber }}
                      <br/>
                      {{ item.customer.fullName }}
                      <br/>
                      <b>{{ item.customer.companyName }}</b>
                    </td>
                    <td>
                      <b>Водитель:</b> {{ item.driver ? item.driver.fullName : 'Нет информации' }}
                      <br/>
                      {{ item.address.addressString }}
                    </td>
                    <td class="text-right">
                      <button type="submit" class="btn btn-danger" @click="closeOrder(item.id)">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                              v-if="buttonActive2[item.id]"></span>
                        Закрыть
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>
</template>

<style scoped>

</style>