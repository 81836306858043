vue
<script setup> import {useUserStore} from "@/store";
import {computed, onBeforeMount, ref} from "vue";

import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useRoute} from "vue-router";
import {parse} from "date-fns";
import format from "date-fns/format";

import {useToast} from 'vue-toastification';

const toast = useToast();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const route = useRoute();
const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function (error) {
    showErrors(error.response)
  });
}
const detail = ref({})

const getDetail = async (id) => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/employee-detail?id=' + id).then(function (response) {
    detail.value = response.data
  }).catch(function (error) {
    console.log(error)
    showErrors(error)
    return []
  })
}

const getDayOfWeek = (dateString) => {
  const date = new Date(dateString);
  const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
  return days[date.getDay()];
};


const formatDate = (dateString) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  return format(date, 'dd.MM.yy');
};

const toggleShowBottlesDetail = (index) => {
  index.showBottlesDetail = index?.showBottlesDetail ? false : true;
}

const toggleShowMoneyDetail = (index) => {
  index.showMoneyDetail = index?.showMoneyDetail ? false : true;
}

const updateAdditionalPayments = () => {
  api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/additional-payments/', {
    employeeId: route.params.id,
    salary: detail.value.data.salary,
    ad: detail.value.data.ad,
    phoneFee: detail.value.data.phoneFee,
    duty: detail.value.data.duty
  }).then(function () {
    toast.success("Данные обновлены")
    getDetail(route.params.id);
  }).catch(function (response) {
    showErrors(response)
  })
}
const updateAdditionalPayment = (interval) => {
  const parsedExpression = interval.intervalAdditionalPayments.additionalPaymentStr.replace(/[^0-9()+\-*/]/g, '');
  try {
    const result = eval(parsedExpression);
    interval.intervalAdditionalPayments.additionalPayment = result ?? 0;

  } catch (e) {
    toast.error("Ведите корректное выражение")
  }
};
const upsertIntervalAdditionalPayment = (interval) => {
  updateAdditionalPayment(interval)
  api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/interval-additional-payments/', {
    id: interval.intervalAdditionalPayments.id,
    staffType: 'Водитель',
    additionalPayment: interval.intervalAdditionalPayments.additionalPayment,
    additionalPaymentStr: interval.intervalAdditionalPayments.additionalPaymentStr,
    additionalPaymentComment: interval.intervalAdditionalPayments.additionalPaymentComment,

  }).then(function () {
    toast.success("Данные обновлены")
    getDetail(route.params.id);
  }).catch(function (response) {
    showErrors(response)
  })
}
onBeforeMount(() => {
  checkAuth();
  getDetail(route.params.id);

});
</script>
<template>
  <div class="content-wrapper" v-if="isAuthenticated"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Детализация</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'DriverSalaryPage'}">Заработная плата</router-link>
              </li>
              <li class="breadcrumb-item active">Водители и экспедиторы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card-danger" v-if="detail.data">
              <div class="info-box">
                <h3 class="card-title">{{ detail.data.title }}</h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <table class="table table-striped table-sm table-bordered">
                    <thead>
                    <tr>
                      <th>Дата</th>
                      <th>Время</th>
                      <th>Количество бутылей и точек</th>
                      <th>Общая сумма</th>
                      <th>Доплаты за текущий период</th>
                      <th>Комментарий к доплате</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(item, index) in detail.data.driverSalaryDayDetailsArray" :key="index">
                      <tr v-for="(interval, intervalIndex) in item.intervalsArray" :key="intervalIndex">
                        <td v-if="intervalIndex === 0 " :rowspan="item.intervalsArray.length+2"> {{
                            formatDate(item.date)
                          }}<br>{{ getDayOfWeek(item.date) }}
                        </td>
                        <td>{{ interval.interval.name }}</td>
                        <td>
                          <div class="data-info" @click="toggleShowBottlesDetail(interval)">
                            {{ interval.driverByDayDetailOrderOptions.bottlesCount }} бутылей<br>
                            {{ interval.driverByDayDetailOrderOptions.fruitDrinkCount }} морсов<br>
                            0 6л бутылей<br>
                            {{ interval.driverByDayDetailOrderOptions.pointsCount }} точек<br></div>
                          <div class="info-box" v-if="interval.showBottlesDetail">
                            Заказов выполнено:&nbsp;{{ interval.driverByDayDetailOrderOptions.pointsCount }}
                            <br>Заказов не выполнено:
                            {{ interval.driverByDayDetailOrderOptions.undeliveredPointsCount }}
                            <br> ---------------------
                            <br>Кулеров доставлено:&nbsp;{{ interval.driverByDayDetailOrderOptions.coolersCount }}
                            <br>Кулеров не доставлено:
                            {{ interval.driverByDayDetailOrderOptions.undeliveredCoolersCount }}
                            <br> ---------------------
                            <br>Бутылей доставлено:&nbsp;{{ interval.driverByDayDetailOrderOptions.bottlesCount }}
                            <br> Бутылей не
                            доставлено:&nbsp;{{ interval.driverByDayDetailOrderOptions.undeliveredBottlesCount }}
                            <br>---------------------
                            <br>Бутылей возвращено:&nbsp;{{ interval.driverByDayDetailOrderOptions.pickUpBottlesCount }}
                            <br>Заказов с возвратом
                            тары:&nbsp;{{ interval.driverByDayDetailOrderOptions.pickUpBottlePointsCount }}
                            <br>---------------------
                            <br>Платная доставка:&nbsp;{{ interval.driverByDayDetailOrderOptions.paidDeliveryCount }}
                          </div>
                        </td>
                        <td>
                          <div class="data-info" @click="toggleShowMoneyDetail(interval)"> {{
                              interval.total
                            }}&nbsp;р.
                          </div>
                          <div class="info-box" v-if="interval.showMoneyDetail">
                            ({{
                              interval.driverByDayDetailOrderOptions.onePointFee
                            }}*{{ interval.driverByDayDetailOrderOptions.pointsCount }}
                            +
                            {{
                              interval.driverByDayDetailOrderOptions.oneBottleFee
                            }}*{{ interval.driverByDayDetailOrderOptions.bottlesCount }}
                            +
                            {{
                              interval.driverByDayDetailOrderOptions.oneCoolerFee
                            }}*{{ interval.driverByDayDetailOrderOptions.coolersCount }}
                            +
                            {{
                              interval.driverByDayDetailOrderOptions.undeliveredPointFee
                            }}*{{ interval.driverByDayDetailOrderOptions.undeliveredPointsCount }}
                            +
                            {{
                              interval.driverByDayDetailOrderOptions.undeliveredBottleFee
                            }}*{{ interval.driverByDayDetailOrderOptions.undeliveredBottlesCount }}
                            +
                            {{
                              interval.driverByDayDetailOrderOptions.undeliveredCoolerFee
                            }}*{{ interval.driverByDayDetailOrderOptions.undeliveredCoolersCount }}
                            +
                            {{
                              interval.driverByDayDetailOrderOptions.pickUpBottleFee
                            }}*{{ interval.driverByDayDetailOrderOptions.pickUpBottlesCount }}
                            +
                            {{
                              interval.driverByDayDetailOrderOptions.pickUpBottlePointFee
                            }}*{{ interval.driverByDayDetailOrderOptions.pickUpBottlePointsCount }})
                            +
                            ({{
                              interval.driverByDayDetailOrderOptions.paidDeliveryFee
                            }}*{{ interval.driverByDayDetailOrderOptions.paidDeliveryCount }})
                            +
                            {{ interval.driverSalaryByDayDetailDeliveryRequestOptions.shiftIsWorked ? '1' : '0' }}*
                            ({{ interval.driverSalaryByDayDetailDeliveryRequestOptions.shiftFee }} +
                            {{
                              interval.driverSalaryByDayDetailDeliveryRequestOptions.less60Bottles ? interval.driverSalaryByDayDetailDeliveryRequestOptions.less60BottlesFee :
                                  interval.driverSalaryByDayDetailDeliveryRequestOptions.less80Bottles ? interval.driverSalaryByDayDetailDeliveryRequestOptions.less80BottlesFee :
                                      interval.driverSalaryByDayDetailDeliveryRequestOptions.less100Bottles ? interval.driverSalaryByDayDetailDeliveryRequestOptions.less100BottlesFee :
                                          interval.driverSalaryByDayDetailDeliveryRequestOptions.moreOrEqual100Bottles ? interval.driverSalaryByDayDetailDeliveryRequestOptions.moreOrEqual100BottlesFee : 0
                            }}
                            +
                            {{
                              interval.driverSalaryByDayDetailDeliveryRequestOptions.suburbanBonusFee
                            }}*{{ interval.driverSalaryByDayDetailDeliveryRequestOptions.gatchinaBonusApplied ? 1 : 0 }}
                            +
                            {{
                              interval.driverSalaryByDayDetailDeliveryRequestOptions.gatchinaBonusFee
                            }}*{{ interval.driverSalaryByDayDetailDeliveryRequestOptions.gatchinaBonusApplied ? 1 : 0 }}
                            + {{ interval.driverSalaryByDayDetailDeliveryRequestOptions.bonus }}
                            ) +
                            ({{
                              interval.driverByDayDetailOrderOptions.oneFruitDrinkFee
                            }}*{{ interval.driverByDayDetailOrderOptions.fruitDrinkCount }})
                            +(0*0)

                            <br><br> (точка *... + бутыли доставлены *... + кулер доставлен *... + недоставленные точки
                            *... + недоставленные бутылки * ... + недоставленный кулер * ... + забор тары * ... + забор
                            тары точка * ... ) + (Платная доставка*...) + (Тариф за выход + тариф от
                            количества бутылей + доплата за пригород + доплата за Гатчина + доплата) + (Морсы) + (6л
                            вода)
                          </div>
                        </td>
                        <td>
                          <div class="col-12"><input type="text" class="form-control" id="last-name" name="last_name"
                                                     value="0"
                                                     v-model="interval.intervalAdditionalPayments.additionalPaymentStr"
                                                     @keyup.enter="updateAdditionalPayment(interval);upsertIntervalAdditionalPayment(interval)">
                          </div>
                          <div class="col-auto">
                            <button type="submit" class="btn btn-success btn-sm"
                                    @click="updateAdditionalPayment(interval)"><i class="fas fa-bars"></i></button>
                          </div>
                          <div class="col-12"><input type="text" class="form-control" id="last-name" name="last_name"
                                                     value="0" readonly=""
                                                     v-model="interval.intervalAdditionalPayments.additionalPayment">
                          </div>
                        </td>
                        <td><textarea name="" v-model="interval.intervalAdditionalPayments.additionalPaymentComment"
                                      @change="upsertIntervalAdditionalPayment(interval)"></textarea></td>
                        <td>
                          <button class="btn btn-success btn-sm" @click="upsertIntervalAdditionalPayment(interval)">
                            Сохранить
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td v-if="item.additionalTariffs.length>0" :rowspan=2> {{
                            formatDate(item.date)
                          }}<br>{{ getDayOfWeek(item.date) }}
                        </td>
                        <td>дневные выплаты</td>
                        <td>
                          <div v-if="item.driverSalaryByDayDetailBaseOptions">
                            Оплата за выход: {{ item.driverSalaryByDayDetailBaseOptions.salaryFee }} р.
                            Оплата за телефон: {{ item.driverSalaryByDayDetailBaseOptions.phoneFee }} р.
                          </div>
                        </td>
                        <td>
                          <div v-if="item.driverSalaryByDayDetailBaseOptions">
                            {{
                              item.driverSalaryByDayDetailBaseOptions.salaryFee + item.driverSalaryByDayDetailBaseOptions.phoneFee
                            }}
                            р.
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr style="border-bottom: 2px solid #2c96a4;">
                        <td>доп тарифы</td>
                        <td>
                          <div v-for="atr in item.additionalTariffs" :key="atr">
                            Тариф: <b>{{ atr.additional_tariff.name }}</b>, за день: {{ atr.salary }} р., за заказы:
                            {{ atr.additional_payment }} р. (кол-во заказов: {{ atr.orders_count }}) <br>
                            Кол-во бутылей: {{ atr.bottles_count }}<br>
                            Кол-во морсов: {{ atr.fruit_drink_count }}<br>
                            Кол-во 6л бутылей: {{ atr.bottles_6l_count }}<br><br>
                          </div>
                        </td>
                        <td v-if="item.additionalTariffs.length>0">
                          <div class="data-info" @click="toggleShowMoneyDetail(item)">
                          {{ item.total }} р.
                        </div>
                          <div v-if="item.showMoneyDetail" @click="toggleShowMoneyDetail(interval)">
                            (
                            <template v-for="(atr, index) in item.additionalTariffs" :key="atr">
                              {{atr.salary}} +  {{atr.additional_payment}}
                              <span v-if="index !== item.additionalTariffs.length - 1"> + </span>
                            </template>
                            )
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>

                    </template>
                    </tbody>
                  </table>

                </div>
              </div>
              <table class="table table-sm table-bordered col-3">
                <tbody>
                <tr>
                  <td>Оклад:</td>
                  <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                             v-model="detail.data.salary"></td>
                </tr>
<!--                <tr>-->
<!--                  <td>Телефон:</td>-->
<!--                  <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"-->
<!--                             v-model="detail.data.phoneFee"></td>-->
<!--                </tr>-->
                <tr>
                  <td>Реклама:</td>
                  <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                             v-model="detail.data.ad"></td>
                </tr>
                <tr>
                  <td>Долг:</td>
                  <td><input type="number" class="form-control" id="last-name" name="last_name" value="0"
                             v-model="detail.data.duty" readonly></td>
                </tr>
                <tr>
                  <td>Итого:</td>
                  <td><input type="number" class="form-control" id="last-name" name="last_name" value="0" readonly
                             v-model="detail.data.total"></td>
                </tr>
                </tbody>
              </table>
              <div class="row mb-2">
                <div class="col-auto">
                  <button type="submit" class="btn btn-success" @click="updateAdditionalPayments">Пересчитать</button>
                </div>
                <div class="col-auto">
                  <button type="submit" class="btn btn-success"><i class="fas fa-print"></i>Распечатать</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>
<style scoped> .custom-spinner {
  z-index: 1000; /* Установите нужное значение z-index */
  position: fixed; /* Фиксированное позиционирование */
  top: 50%; /* Центрирование по вертикали */
  left: 50%; /* Центрирование по горизонтали */
  transform: translate(-50%, -50%); /* Центрирование по оси */
  background: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
  width: 100vw; /* Полная ширина экрана */
  height: 100vh; /* Полная высота экрана */
  display: flex;
  flex-direction: column; /* Вертикальное расположение элементов */
  justify-content: center;
  align-items: center;
}

.table {
  font-size: 14px;
}

.table th {
  font-size: 12px;
}

.data-info {
  cursor: pointer;
}

</style>