<script setup>
import { onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";
import DepartmentPageMenu from "@/components/Departments/DepartmentMenu.vue";
import {useRoute} from "vue-router";

const posts = ref([]);
const targetPostId = ref(null);
const toast = useToast();


const getDepartmentPosts = async (id) => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + id + '/posts').then(function (response) {
    posts.value = response.data.data;
  }).catch(function (error) {
    showErrors(error.response);
  });
}

const deletePost = async (id) => {
  await api.delete(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/post/' + id).then(function () {
    toast.success("Должность удалена");
    posts.value = posts.value.filter(post => post.id !== id);
  }).catch(function (error) {
    showErrors(error.response);
  });
}
const route = useRoute();

onBeforeMount(() => {
  getDepartmentPosts(route.params.id)

});
</script>
<template>
  <div class="content-wrapper"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Должности отдела</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Отделы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <DepartmentPageMenu></DepartmentPageMenu>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Список должностей</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" size="25" v-model="targetPostId">
                        <option v-for="post in posts" :key="post.id" :value="post.id">{{ post.name }}</option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info" @click="$router.push({name: 'CreatePostPage'})">Добавить новую
                  </button>
                </div>
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info"    @click="$router.push({name: 'EditPostPage', params: { postId: targetPostId }})"
                          :disabled="!targetPostId">Редактировать выбранную
                  </button>
                </div>
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-danger" @click="deletePost(targetPostId)" :disabled="!targetPostId">
                    Удалить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>
