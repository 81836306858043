<template>
  <footer class="main-footer">
    <strong>HW © 2024</strong>
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 0.1.1
    </div>
  </footer>
</template>


