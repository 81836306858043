<script setup>
import {onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import router from "@/router";
import {useToast} from "vue-toastification";

const department = ref({
  name: '',
  waterCompaniesIds: [],
  ownerId: null
})

const users = ref([]);
const getUsers = async () => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/users-without-owner').then(function (response) {
    users.value = response.data
  }).catch(function (error) {
    showErrors(error)
  })
}


const toast = useToast();
const addDepartment =  () => {
  api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department',department.value).then(function () {
    toast.success("Отдел добавлен")
    router.push({name: 'DepartmentsPage'})
  }).catch(function (error) {
    showErrors(error.response)
  })
}
onBeforeMount(() => {
  getUsers();
});
</script>
<template>
  <div class="content-wrapper"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Добавить новый отдел</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Отделы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                  <div class="card card-primary card-outline">
                    <form>
                      <div class="form-group">
                        <label>Название</label>
                        <input v-model="department.name" type="text" class="form-control">
                      </div>
                      <div class="form-group">
                        <label>Город</label>
                        <select multiple class="form-control" v-model="department.waterCompaniesIds">
                          <option value=2>Москва</option>
                          <option value=1>Санкт-Петербург</option>
                        </select>
                      </div>
                    </form>
                    <div class="card-footer">
                      <button type="submit" class="btn btn-info" @click="addDepartment">Добавить</button>
                      <button type="submit" class="btn btn-default float-right" @click="router.push({name: 'DepartmentsPage'})">Отмена</button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                  <div class="card card-primary card-outline">
                    <form>
                      <div class="form-group">
                        <label>Руководитель</label>
                      </div>
                      <div class="form-group">
                        <select  class="form-control" v-model="department.ownerId">
                        <option v-for="user in users.data" :key="user.id" :value="user.id">{{ user.name }}</option>
                        </select>
                      </div>
                    </form>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>
