<script setup>
import NavBar from "@/components/Common/NavBar.vue";
import SideBar from "@/components/Common/SideBar.vue";
import FooterBar from "@/components/Common/FooterBar.vue";

import {useUserStore} from "@/store";
import {computed, onMounted} from "vue";
import router from "@/router";
import { useParamsStore } from "@/store/params";

const userStore = useUserStore();
const isAuth = computed(() => userStore.user.isAuthenticated);

const showMenu = computed(() => {
  if (isAuth.value && (router.currentRoute.value.name !== 'SingIn' || router.currentRoute.value.name !== 'StaffSingIn')) {
    return true
  }
  return false
});

onMounted (() =>
useParamsStore().fetchCities()
)
</script>

<template>
  <div class="wrapper">
    <NavBar v-show=showMenu />
    <SideBar v-show="showMenu" />
    <router-view></router-view>
    <FooterBar v-show=showMenu />
  </div>
</template>

<style>
html, body {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 100%;
  height: auto !important;
  position: absolute !important;
}
</style>
