import {createWebHistory, createRouter} from "vue-router";
import MainPage from "@/components/MainPage/MainPage.vue";
import PageNotFound from "@/components/PageNotFound/PageNotFound.vue";
import SingInPage from "@/components/Auth/SingInPage.vue";
import StaffSingInPage from "@/components/Auth/StaffSingInPage.vue";
import DeliveredOrders from "@/components/Orders/DeliveredOrdersPage.vue";
import DeliveredOrdersStats from "@/components/Orders/DeliveredOrdersStatsPage.vue";
import StaffProfile from "@/components/Staff/StaffProfilePage.vue";
import StaffListPage from "@/components/Staff/StaffListPage.vue";
import StaffCard from "@/components/Staff/StaffCard.vue";
import DriverSalaryPage from "@/components/Salary/DriverSalaryPage.vue";
import SalaryWeekendPage from "@/components/Salary/SalaryWeekendPage.vue";
import TariffPage from "@/components/Salary/TariffPage.vue";
import DriverBaseTariffPage from "@/components/Salary/DriverBaseTariffPage.vue";
import ForwarderBaseTariffPage from "@/components/Salary/ForwarderBaseTariffPage.vue";
import DriverCalculationPage from "@/components/Salary/DriverCalculationPage.vue";
import PeriodListPage from "@/components/Salary/PeriodListPage.vue";
import PeriodPage from "@/components/Salary/PeriodPage.vue";
import DriverSalaryDetailPage from "@/components/Salary/DriverSalaryDetailPage.vue";
import ForwarderSalaryDetailPage from "@/components/Salary/ForwarderSalaryDetailPage.vue";
import AdditionalTariffPage from "@/components/Salary/AdditionalTariffPage.vue";
import AdditionalTariffsPage from "@/components/Salary/AdditionalTariffsPage.vue";
import DataMatrixPage from "@/components/DataMatrix/DataMatrixPage.vue";
import AutoPaymentLogPage from "@/components/AutoPayment/AutoPaymentLogPage.vue";
import AutoPaymentPage from "@/components/AutoPayment/AutoPaymentPage.vue";
import AutoPaymentSettingPage from "@/components/AutoPayment/AutoPaymentSettingPage.vue";
import StatementsPage from "@/components/Statements/StatementsPage.vue";
import DriversNotifySettingPage from "@/components/Notification/DriversNotifySettingPage.vue";
import SberbankLogPage from "@/components/Service/SberbankLog/SberbankLogPage.vue";
import CloseOrderPage from "@/components/Orders/CloseOrderPage.vue";
import CustomerSearch from "@/components/Customers/CustomerSearch.vue";
import ReportsMain from "@/components/Reports/ReportsMain.vue";
import SettingsMain from "@/components/Settings/SettingsMain.vue";
import WarehouseMain from "@/components/Warehouse/WarehouseMain.vue";
import UserMain from "@/components/User/UserMain.vue";
import CustomerEquipment from "@/components/Customers/CustomerEquipment.vue";
import AddOrder from "@/components/Orders/AddOrder.vue";
import DepartmentsPage from "@/components/Departments/DepartmentsPage.vue";
import CreateDepartmentPage from "@/components/Departments/CreateDepartmentPage.vue";
import DepartmentPostsPage from "@/components/Departments/DepartmentPostsPage.vue";
import DepartmentMainSettingsPage from "@/components/Departments/DepartmentMainSettingsPage.vue";
import DepartmentUsersPage from "@/components/Departments/DepartmentUsersPage.vue";
import CreatePostPage from "@/components/Departments/CreatePostPage.vue";
import EditPostPage from "@/components/Departments/EditPostPage.vue";
import StatementDetailPage from "@/components/Statements/StatementDetailPage.vue";
import CreateTransactionPage from "@/components/Transaction/CreateTransactionPage.vue";
import StaffForManagerSheetsPage from "@/components/Staff/StaffForManagerSheetsPage.vue";
import SendNotificationPage from "@/components/Staff/SendNotificationPage.vue";
import StaffForManagerBalancePage from "@/components/Staff/StaffForManagerBalancePage.vue";
import StaffDebtPage from "@/components/Staff/StaffDebtPage.vue";
import CreateDebtPage from "@/components/Staff/CreateDebtPage.vue";
import BalancesPage from "@/components/Staff/BalancesPage.vue";
import {useUserStore} from "@/store";

const routes = [
    {
        path: "/",
        name: "MainPage",
        component: MainPage,
    },
    {
        path: "/sing-in",
        name: "SingIn",
        component: SingInPage,

    },
    {
        path: "/staff/sing-in",
        name: "StaffSingIn",
        component: StaffSingInPage,
    },
    {
        path: "/orders/delivered",
        name: "DeliveredOrders",
        component: DeliveredOrders,
    },
    {
        path: "/orders/delivered-stats",
        name: "DeliveredOrdersStats",
        component: DeliveredOrdersStats,
    },
    {
        path: "/orders/close",
        name: "CloseOrderPage",
        component: CloseOrderPage
    },
    {
        path: "/orders/add",
        name: "AddOrder",
        component: AddOrder
    },
    {
        path: "/staff/profile",
        name: "StaffProfile",
        component: StaffProfile,
    },
    {
        path: "/staff-list",
        name: "StaffListPage",
        component: StaffListPage,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            if (userRole === "admin" || userRole === "user" || userRole === "departmentOwner") {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/staff/:id",
        name: "StaffCard",
        component: StaffCard,
        props: true
    },
    {
        path: "/salary/drivers",
        name: "DriverSalaryPage",
        component: DriverSalaryPage
    },
    {
        path: "/salary/drivers/weekends",
        name: "SalaryWeekendPage",
        component: SalaryWeekendPage
    },
    {
        path: "/salary/drivers/tariffs",
        name: "TariffPage",
        component: TariffPage
    },
    {
        path: "/salary/drivers/additional-tariff/:id",
        name: "AdditionalTariffPage",
        component: AdditionalTariffPage
    },
    {
        path: "/salary/drivers/additional-tariffs",
        name: "AdditionalTariffsPage",
        component: AdditionalTariffsPage
    },
    {
        path: "/salary/drivers/tariffs/d/base",
        name: "DriverBaseTariffPage",
        component: DriverBaseTariffPage
    },
    {
        path: "/salary/drivers/tariffs/f/base",
        name: "ForwarderBaseTariffPage",
        component: ForwarderBaseTariffPage
    },
    {
        path: "/salary/drivers/calculation",
        name: "DriverCalculationPage",
        component: DriverCalculationPage
    },
    {
        path: "/salary/drivers/period-list",
        name: "PeriodListPage",
        component: PeriodListPage
    },
    {
        path: "/salary/drivers/period/:id",
        name: "PeriodPage",
        component: PeriodPage,
        props: true
    },
    {
        path: "/salary/drivers/detail/d/:id",
        name: "DriverSalaryDetailPage",
        component: DriverSalaryDetailPage,
        props: true
    },
    {
        path: "/salary/drivers/detail/f/:id",
        name: "ForwarderSalaryDetailPage",
        component: ForwarderSalaryDetailPage,
        props: true
    },
    {
        path: "/order/datamatrix/create",
        name: "DataMatrixPage",
        component: DataMatrixPage,
        props: true
    },
    {
        path: "/customer/log/auto-payment",
        name: "AutoPaymentLogPage",
        component: AutoPaymentLogPage,
        props: true
    },
    {
        path: "/customer/auto-payment",
        name: "AutoPaymentPage",
        component: AutoPaymentPage,
        props: true
    },
    {
        path: "/customer/settings/auto-payment",
        name: "AutoPaymentSettingPage",
        component: AutoPaymentSettingPage,
        props: true
    },
    {
        path: "/statements",
        name: "StatementsPage",
        component: StatementsPage,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            const userPermissions = store.user.permissions;
            if ((userRole === "admin") || userPermissions?.includes('view_statements')) {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },

    {
        path: "/statement/:id/detail",
        name: "StatementDetailPage",
        component: StatementDetailPage,
        props: true,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            const userPermissions = store.user.permissions;
            if ((userRole === "admin") || userPermissions?.includes('view_statements')) {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },

    {
        path: "/notification/drivers",
        name: "DriversNotifySettingPage",
        component: DriversNotifySettingPage
    },

    {
        path: "/service/sberbank-log",
        name: "SberbankLogPage",
        component: SberbankLogPage
    },

    {
        path: "/customers/search",
        name: "CustomerSearch",
        component: CustomerSearch
    },

    {
        path: "/customers/equipment",
        name: "CustomerEquipment",
        component: CustomerEquipment
    },

    {
        path: "/reports/",
        name: "ReportsMain",
        component: ReportsMain
    },

    {
        path: "/settings/",
        name: "SettingsMain",
        component: SettingsMain
    },

    {
        path: "/warehouse/",
        name: "WarehouseMain",
        component: WarehouseMain
    },

    {
        path: "/user/",
        name: "UserMain",
        component: UserMain
    },

    {
        path: "/departments",
        name: "DepartmentsPage",
        component: DepartmentsPage,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            if (userRole === "admin") {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },


    {
        path: "/department/new",
        name: "CreateDepartmentPage",
        component: CreateDepartmentPage,
        props: true,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            if (userRole === "admin") {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/department/:id/main",
        name: "DepartmentMainSettingsPage",
        component: DepartmentMainSettingsPage,
        props: true,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            if (userRole === "admin") {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/department/:id/users",
        name: "DepartmentUsersPage",
        component: DepartmentUsersPage,
        props: true,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            if (userRole === "admin" || userRole === "departmentOwner") {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/department/:id/posts",
        name: "DepartmentPostsPage",
        component: DepartmentPostsPage,
        props: true,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            if (userRole === "admin" || userRole === "departmentOwner") {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/department/:id/post/new",
        name: "CreatePostPage",
        component: CreatePostPage,
        props: true,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            if (userRole === "admin" || userRole === "departmentOwner") {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/department/:id/post/:postId",
        name: "EditPostPage",
        component: EditPostPage,
        props: true,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            if (userRole === "admin" || userRole === "departmentOwner") {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },

    {
        path: "/staff/:staffId/transaction/create",
        name: "CreateTransactionPage",
        component: CreateTransactionPage,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            const userPermissions = store.user.permissions;
            if ((userRole === "admin") || userPermissions?.includes('create_transactions')) {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/staff-for-manager/:staffId/sheets",
        name: "StaffForManagerSheetsPage",
        component: StaffForManagerSheetsPage,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            const userPermissions = store.user.permissions;
            if ((userRole === "admin") || userPermissions?.includes('create_transactions')) {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/staff-for-manager/:staffId/notification",
        name: "SendNotificationPage",
        component: SendNotificationPage,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            const userPermissions = store.user.permissions;
            if ((userRole === "admin") || userPermissions?.includes('send_balance_notification')) {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/staff-for-manager/:staffId/balance",
        name: "StaffForManagerBalancePage",
        component: StaffForManagerBalancePage,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            const userPermissions = store.user.permissions;
            if ((userRole === "admin") || userPermissions?.includes('view_balance')) {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/staff-for-manager/:staffId/staff-debt",
        name: "StaffDebtPage",
        component: StaffDebtPage,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            const userPermissions = store.user.permissions;
            if ((userRole === "admin") || userPermissions?.includes('view_staff_debt')) {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/staff-for-manager/:staffId/staff-debt-create",
        name: "CreateDebtPage",
        component: CreateDebtPage,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            const userPermissions = store.user.permissions;
            if ((userRole === "admin") || userPermissions?.includes('create_staff_debts')) {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },
    {
        path: "/balances",
        name: "BalancesPage",
        component: BalancesPage,
        beforeEnter: (to, from, next) => {
            const store = useUserStore();
            const userRole = store.user.role;
            const userPermissions = store.user.permissions;
            if ((userRole === "admin") || userPermissions?.includes('view_all_balances')) {
                next();
            } else {
                next({ name: "MainPage" });
            }
        }
    },

    {path: '/:pathMatch(.*)*', component: PageNotFound},

];


const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;